import { Trans } from 'react-i18next'
import { Divider, Text, VStack } from '@chakra-ui/react'

import { NeedHelpTextOnlyInfoBox } from 'components/HelpInfo/NeedHelpTextOnlyInfoBox'

import BulletPointList from 'pages/ErrorBoundary/components/BulletPointList'
import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

import ICALink from '../components/ICALink'

interface InvalidCitizenshipErrorProps extends ErrorProps {
  campaignName: string
  eligibleCriteria: string[]
}

export const InvalidCitizenshipError = ({
  t,
  campaignName,
  eligibleCriteria,
}: InvalidCitizenshipErrorProps) => {
  return (
    <VStack divider={<Divider borderColor="neutral.300" />} spacing="24px">
      <ErrorHeading
        title={t('invalidResidentialStatus.title', { campaignName })}
        componentContent={
          <VStack alignItems="start">
            <Text variant="bodyLight">
              <Trans
                t={t}
                i18nKey={'invalidResidentialStatus.eligibilityHeader'}
              >
                To be eligible to claim, you must be a:
              </Trans>
            </Text>
            <BulletPointList points={eligibleCriteria} />
          </VStack>
        }
      />
      <NeedHelpTextOnlyInfoBox>
        <Trans t={t} i18nKey={'invalidResidentialStatus.helpInfo'}>
          If you are eligible, and are unable to claim your vouchers, please
          reach out to
          <ICALink /> for help
        </Trans>
      </NeedHelpTextOnlyInfoBox>
    </VStack>
  )
}
