import HelpInfoTextOnly from './HelpInfoTextOnly'
import { NeedHelpInfoBox } from './NeedHelpInfoBox'

type NeedHelpTextOnlyInfoBoxProps = {
  children: React.ReactNode
}

export const NeedHelpTextOnlyInfoBox = ({
  children,
}: NeedHelpTextOnlyInfoBoxProps) => {
  return (
    <NeedHelpInfoBox>
      <HelpInfoTextOnly>{children}</HelpInfoTextOnly>
    </NeedHelpInfoBox>
  )
}
