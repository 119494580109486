import { BiRightArrowAlt } from 'react-icons/bi'
import { Button } from '@chakra-ui/react'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

interface NotAuthorisedErrorProps extends ErrorProps {
  handleRedirectLogin: () => void
}

// TODO: Add translations for this error page
export const NotAuthorisedError = ({
  t,
  handleRedirectLogin,
}: NotAuthorisedErrorProps) => (
  <>
    <ErrorHeading title={t('pageError')} />{' '}
    <Button
      onClick={handleRedirectLogin}
      rightIcon={<BiRightArrowAlt />}
      children={t('buttons.returnLogin')}
    />
  </>
)
