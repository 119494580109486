import callback from './callbackTA.json'
import common from './commonTA.json'
import contactOld from './contactOldTA.json'
import contact from './contactTA.json'
import error from './errorTA.json'
import footer from './footerTA.json'
import listing from './listingTA.json'
import loading from './loadingTA.json'
import login from './loginTA.json'
import register from './registerTA.json'
import success from './successTA.json'
import trial from './trialTA.json'
import verify from './verifyTA.json'

export const translationsTA = {
  common,
  callback,
  contact,
  contactOld,
  error,
  footer,
  listing,
  loading,
  login,
  register,
  success,
  trial,
  verify,
}
