import { ComponentMultiStyleConfig } from '@chakra-ui/react'

import { textStyles } from '../textStyles'

export const CampaignCard: ComponentMultiStyleConfig = {
  parts: ['container', 'title', 'description', 'useByDate', 'button', 'label'],
  baseStyle: {
    container: {
      bgColor: 'standard.white',
      borderRadius: '8px',
      borderColor: 'neutral.100',
      boxShadow: '0px 0px 10px rgba(216, 222, 235, 0.5)',
      padding: '20px',
    },
    title: {
      ...textStyles.h5,
      color: 'primary.700',
      marginTop: '12px',
      fontWeight: 600,
    },
    description: {
      marginTop: '4px',
      ...textStyles.body1,
      color: 'neutral.700',
    },
    useByDate: {
      ...textStyles.body2,
      color: 'primary.500',
      textAlign: 'center',
    },
    button: {
      bgColor: 'primary.500',
      color: 'standard.white',
      borderRadius: '50%',
      height: '36px',
      width: '36px',
      maxHeight: '36px',
      maxWidth: '36px',
      minHeight: '36px',
      minWidth: '36px',
    },
    label: {
      textTransform: 'none',
      backgroundColor: 'primary.200',
      color: 'primary.600',
      borderRadius: '4px',
      paddingY: '2px',
      paddingX: '6px',
      ...textStyles.caption1,
    },
  },
  sizes: {
    lg: {
      container: {
        padding: '32px',
        borderRadius: '16px',
      },
      title: {
        ...textStyles.h2,
        marginTop: '16px',
      },
      description: {
        ...textStyles.h5,
        marginTop: '8px',
      },
      useByDate: {
        ...textStyles.subhead2,
      },
      button: {
        height: '44px',
        width: '44px',
        maxHeight: '44px',
        maxWidth: '44px',
        minHeight: '44px',
        minWidth: '44px',
      },
      label: {
        paddingX: '8px',
        ...textStyles.subhead1,
      },
    },
  },
}
