// Translations
import { useTranslation } from 'react-i18next'
// Constants
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import {
  Container,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils'
import * as CSS from 'csstype'

import OgpLogoImage from 'images/ogp-logo-black.svg'
import RedeemLogoPng from 'images/redeemsg-full.png'
import RedeemLogoSvg from 'images/redeemsg-full.svg'
import { REDEEM_CONSTANTS } from 'constants/config'

import OGPLogo from './OGPLogo'

interface FooterProps {
  desktopPosition?: Token<CSS.Property.Position>
}

export const Footer = ({ desktopPosition = 'absolute' }: FooterProps) => {
  // Hooks
  const { t } = useTranslation('footer')
  // TODO: Fix overflow

  const isMobileWidth = useBreakpointValue({ base: true, lg: false })

  return isMobileWidth ? (
    <Container
      maxW="1504px"
      w="100%"
      px={{ base: 8, md: 12 }}
      backgroundColor="primary.100"
    >
      <Stack
        pt={{ base: '56px', md: '48px' }}
        pb="40px"
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ md: 'space-between' }}
        alignItems={{ md: 'flex-end' }}
        color="secondary.500"
        spacing="24px"
        textStyle="body2"
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '8px', md: '16px' }}
          color="primary.500"
        >
          <Image src={RedeemLogoSvg} fallbackSrc={RedeemLogoPng} maxW="40%" />
          <Text color="primary.500">{t('description')}</Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '16px', md: '22px' }}
          color="primary.500"
        >
          <Link href={REDEEM_CONSTANTS.privacy} isExternal>
            <Text textStyle="body2">{t('privacy')}</Text>
          </Link>
          <Link href={REDEEM_CONSTANTS.termsOfUse} isExternal>
            <Text textStyle="body2">{t('termsOfUse')}</Text>
          </Link>
          <Link href={REDEEM_CONSTANTS.reportVulnerability} isExternal>
            <Text textStyle="body2">{t('reportVulnerability')}</Text>
          </Link>
        </Stack>
      </Stack>
      <Divider borderColor="neutral.300" />
      <Stack
        py="48px"
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ md: 'space-between' }}
        alignItems={{ md: 'flex-end' }}
        color="primary.500"
        spacing={{ base: '32px', md: '0px' }}
      >
        <VStack
          justifyContent="flex-start"
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Text textStyle="caption1" width="100%">
            Built by
          </Text>
          <Link href="https://open.gov.sg" isExternal>
            <Image
              htmlWidth="160px"
              htmlHeight="47px"
              src={OgpLogoImage}
              alt="Open Government Products Logo"
              loading="lazy"
            />
          </Link>
        </VStack>

        <VStack align="stretch">
          <HStack color="black" justifyContent={{ md: 'flex-end' }}>
            <Link
              color="standard.black"
              href="https://www.linkedin.com/company/open-government-products/"
              isExternal
              _hover={{
                color: 'secondary.500',
              }}
            >
              <FaLinkedinIn size="28" style={{ marginRight: '5px' }} />
            </Link>
            <Link
              color="standard.black"
              href="https://www.facebook.com/opengovsg"
              isExternal
              _hover={{
                color: 'secondary.500',
              }}
            >
              <FaFacebookF size="24" style={{ marginRight: '5px' }} />
            </Link>
            <Link
              color="standard.black"
              href="https://open.gov.sg"
              isExternal
              _hover={{
                color: 'secondary.500',
              }}
            >
              <OGPLogo />
            </Link>
          </HStack>
          <Link href="https://open.gov.sg" isExternal>
            <Text
              textStyle="legal"
              _hover={{
                color: 'primary.500',
              }}
            >
              ©2024 Open Government Products, Government Technology Agency of
              Singapore
            </Text>
          </Link>
        </VStack>
      </Stack>
    </Container>
  ) : (
    <HStack
      position={desktopPosition}
      bottom={0}
      width="100%"
      align="end"
      justify="start"
      spacing="0px"
      // PointerEvents none as this footer is using position absolute, with it at the bottom. In the event there is anything clickable
      // it will block the pointer events behind this footer. Thus pointerEvents is set to none to allow the clicks to fallthrough. However,
      // this will the links in the footer unclickable, hence need to manually set their pointerEvents to auto
      pointerEvents="none"
      padding="20px"
    >
      <VStack minW="40%" align="start" spacing="8px" paddingLeft="5%">
        <Image
          src={RedeemLogoSvg}
          fallbackSrc={RedeemLogoPng}
          maxH="30px"
          maxW="160px"
        />
        <Text textStyle="footer">
          &copy; 2024 Open Government Products, GovTech Singapore
        </Text>
      </VStack>
      <Flex flex={1} justify="center">
        <HStack
          width="514px"
          textStyle="footer"
          opacity="0.85"
          justify="space-between"
        >
          <Link
            variant="footerDesktop"
            href={REDEEM_CONSTANTS.privacy}
            isExternal
            pointerEvents="auto"
          >
            {t('privacy')}
          </Link>
          <Link
            variant="footerDesktop"
            href={REDEEM_CONSTANTS.termsOfUse}
            isExternal
            pointerEvents="auto"
          >
            {t('termsOfUse')}
          </Link>
          <Link
            variant="footerDesktop"
            href={REDEEM_CONSTANTS.reportVulnerability}
            isExternal
            pointerEvents="auto"
          >
            {t('reportVulnerability')}
          </Link>
        </HStack>
      </Flex>
    </HStack>
  )
}
