import { useCallback } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import _ from 'lodash'

import { MAX_NETWORK_RETRIES } from 'constants/config'
import { CampaignDto, ListCampaignEntryDto } from 'constants/types'
import { SignupException } from 'errors'
import { getVoucherTotalAmount } from 'utils/helpers'
import {
  isBadRequestError,
  isClientError,
  isNotFoundError,
} from 'utils/service'
import { getCampaign, getListOfPublicCampaigns } from 'services/RedeemApi'

interface GetCampaignByIdParams {
  campaignId: string
}

export function useGetCampaignById({ campaignId }: GetCampaignByIdParams) {
  const handleError = useErrorHandler()

  const { data, error, isLoading } = useQuery(
    ['campaign', campaignId],
    () => getCampaign(campaignId),
    {
      // Select transforms the data on the client side to a format that is expected
      // TODO: More granular api response that deals with these
      select: (data): CampaignDto => {
        if (_.isEmpty(data.defaultVouchers)) {
          // TODO:  find a more elegant way to deal with this throwing
          // This currently works as error component doesnt reset its boundary after the first handleError
          throw handleError(
            new SignupException({
              type: 'non_default_campaign',
            }),
          )
        }

        const voucherTotalAmount = getVoucherTotalAmount(data.defaultVouchers)
        // Checks if campaign is trial via description regex
        const isTrial = /trial/g.test(data.description.toLowerCase())

        // TODO: Remove override once PR https://github.com/datagovsg/redeem-api/pull/753 in
        return {
          ...data,
          voucherTotalAmount,
          isTrial,
        }
      },
      staleTime: Infinity,
      retry: (failureCount, err: SignupException | AxiosError) => {
        return !isClientError(err) && failureCount < MAX_NETWORK_RETRIES
      },
      // TODO: add campaign not found type to api
      onError: (err) => {
        if (isNotFoundError(err) || isBadRequestError(err)) {
          handleError(
            new SignupException({
              type: 'campaign_not_found',
            }),
          )
        } else {
          handleError(err)
        }
      },
      // Query only runs when truthy campaignId is provided
      enabled: !!campaignId,
    },
  )

  return {
    campaignData: data,
    isLoading,
    error,
  }
}

export function useGetListOfCampaigns() {
  const { data, error, isLoading } = useQuery(
    'campaigns',
    () => getListOfPublicCampaigns(),
    {
      // Transforms campaign listing object to include total voucherAmount
      select: useCallback(
        (data): ListCampaignEntryDto[] =>
          _.map(data, (campaign) => {
            return {
              ...campaign,
              voucherTotalAmount: getVoucherTotalAmount(
                campaign.defaultVouchers,
              ),
            }
          }),
        [],
      ),

      retry: (failureCount, err: AxiosError) => {
        return !isClientError(err) && failureCount < MAX_NETWORK_RETRIES
      },
    },
  )

  return {
    data,
    isLoading,
    error,
  }
}
