import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'context/AuthContext'
import { useCampaignContext } from 'context/CampaignContext'

import SingpassLoadingPng from 'images/singpass-loading.png'
import SingpassLoadingSvg from 'images/singpass-loading.svg'
import { FormPhase } from 'constants/types'
import DetailsLayout from 'components/DetailsLayout'
import { Loading } from 'components/Loading'

export const DetailsPage = () => {
  const { person, setFormPhase } = useAuth()
  const history = useHistory()
  const { campaign } = useCampaignContext()

  const { t } = useTranslation('callback')

  const getMobileNumber = () => {
    setFormPhase(FormPhase.ENTER_NUMBER)
    history.push(`/${campaign?.id}/form/register`)
  }

  return person ? (
    <DetailsLayout
      person={person}
      title={
        person.isExisting ? t('confirmDetailsClaimed') : t('confirmDetails')
      }
      content={person.isExisting ? t('confirmDetailsClaimedInfo') : undefined}
      onPrimaryClick={getMobileNumber}
    />
  ) : (
    <Loading
      title={'myInfoLoading'}
      imageSrc={SingpassLoadingSvg}
      fallbackImageSrc={SingpassLoadingPng}
    />
  )
}
