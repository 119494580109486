import { Trans } from 'react-i18next'
import { Divider, Text, VStack } from '@chakra-ui/react'

import { NeedHelpTextOnlyInfoBox } from 'components/HelpInfo/NeedHelpTextOnlyInfoBox'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

import BulletPointList from '../components/BulletPointList'
import ICALink from '../components/ICALink'

interface InvalidAddressErrorProps extends ErrorProps {
  campaignName: string
}

export const InvalidAddressError = ({
  t,
  campaignName,
}: InvalidAddressErrorProps) => {
  const bulletPoints = ['Valid address in Singapore']

  return (
    <VStack divider={<Divider borderColor="neutral.300" />} spacing="24px">
      <ErrorHeading
        title={t('invalidAddress.title', { campaignName })}
        componentContent={
          <VStack alignItems="start">
            <Text variant="bodyLight">
              <Trans t={t} i18nKey={'invalidAddress.eligibilityHeader'}>
                To be eligible to claim, you must have a:
              </Trans>
            </Text>
            <BulletPointList points={bulletPoints} />
          </VStack>
        }
      />
      <NeedHelpTextOnlyInfoBox>
        <Trans t={t} i18nKey={'invalidAddress.helpInfo'}>
          To update your registered address to your current Singaporean address,
          please go to
          <ICALink />
        </Trans>
      </NeedHelpTextOnlyInfoBox>
    </VStack>
  )
}
