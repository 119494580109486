import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const DEFAULT_SAMPLING_RATE = 1

export enum DDAction {
  LOGIN_SINGPASS_BUTTON = 'Login Singpass button',
  NEED_HELP_BUTTON_PHONE = 'Phone Need help button',
  CLOSE_NEED_HELP_BUTTON_PHONE = 'Close Need help phone button',
  NEED_HELP_BUTTON_ERROR = 'Error Need help button',
  CLOSE_NEED_HELP_BUTTON_ERROR = 'Close Need help error button',
  CHANGE_LANGUAGE_BUTTON = 'Change Language button',
  VIEW_VOUCHER_BUTTON = 'View Voucher button',
  SUBMIT_PHONE_BUTTON = 'Submit Phone button',
  RESEND_OTP_BUTTON = 'Resend OTP button',
  STILL_NOT_RECEIVING_SMS_BUTTON = 'Still not receiving sms button',
  CLOSE_SMS_NOT_RECEIVING_BUTTON = 'Close SMS unreceived modal',
  DID_NOT_RECEIVE_SMS_BUTTON = 'Did not receive sms button',
  CLOSE_SMS_NOT_RECEIVED_BUTTON = 'Close SMS unreceived modal',

  // Custom Actions
  ADVISORY_LINK_BUTTON = 'Advisory Link button',
  TERMS_AND_CONDITIONS_BUTTON = 'Terms And Conditions button',
  FEEDBACK_LINK_BUTTON = 'Feedback Link button',
  CONTACT_LINK_BUTTON = 'Contact Link button',
  ICA_INCORRECT_ADDRESS = 'Incorrect Address button',
  SELECT_CAMPAIGN_FROM_LISTING = 'Selected campaign from listing',
  LISTING_PAGE_FAQ_BUTTON = 'Listing page faq button',
}

export function initMonitoringIfTokensPresent(): void {
  if (
    !process.env.REACT_APP_DATADOG_APPLICATION_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    return
  }
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'redeem-signup',
    env: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    // Sample rate in % (i.e 100 refers to all sessions)
    sampleRate:
      Number(process.env.REACT_APP_DATADOG_SAMPLE_RATE) ||
      DEFAULT_SAMPLING_RATE,
    trackInteractions: true,
    // Allows integration with DataDog Traces
    allowedTracingOrigins: [/https:\/\/.*(\.)?redeem\.gov\.sg/],
  })

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'redeem-signup',
    env: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    // Sample rate in % (i.e 100 refers to all sessions)
    sampleRate: 100,
    forwardErrorsToLogs: true,
    beforeSend: (log) => {
      // Don't log XHR aborted requests
      if (log.http?.status_code === 0) {
        return false
      }
    },
  })

  datadogRum.startSessionReplayRecording()
}

export default initMonitoringIfTokensPresent
