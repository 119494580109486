import axios, {
  AxiosError,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
} from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import { REDEEM_API_URL } from 'constants/config'
import { transformAxiosError } from 'utils/service'

const ApiService = axios.create({
  baseURL: REDEEM_API_URL,

  transformRequest: [
    (data) => (data ? snakecaseKeys(data, { deep: true }) : data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],

  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) => (data ? camelcaseKeys(data, { deep: true }) : data),
  ],
})

ApiService.interceptors.response.use(
  (response) => response,
  (err: AxiosError) => {
    throw transformAxiosError(err)
  },
)

export default ApiService
