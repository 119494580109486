import { CampaignDto } from 'constants/types'
import HelpInfoOrganiserContent from 'components/HelpInfo/HelpInfoOrganiserContent'
import { NeedHelpInfoBox } from 'components/HelpInfo/NeedHelpInfoBox'

import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

import TemplateError from './TemplateError'

interface BlacklistedErrorProps extends ErrorProps {
  campaign?: CampaignDto
}

export const BlacklistedError = ({ t, campaign }: BlacklistedErrorProps) => {
  return (
    <TemplateError
      titleText={t('blacklistedAddress.title')}
      infoText={t('blacklistedAddress.info', {
        organiserName: campaign?.organiserName,
      })}
      helpInfoBox={
        !!campaign && (
          <NeedHelpInfoBox>
            <HelpInfoOrganiserContent campaign={campaign} />
          </NeedHelpInfoBox>
        )
      }
    />
  )
}
