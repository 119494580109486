import { TFunction } from 'react-i18next'
import { Divider, Text, useBreakpointValue, VStack } from '@chakra-ui/react'

import { CampaignDto } from 'constants/types'
import { DDAction } from 'utils/monitoring'
import HelpInfoOrganiserContent from 'components/HelpInfo/HelpInfoOrganiserContent'
import { NeedHelpInfoBox } from 'components/HelpInfo/NeedHelpInfoBox'
import LinkModal from 'components/LinkModal'

interface OtpFormHelpProps {
  campaign: CampaignDto
  t: TFunction<'verify', undefined>
}

export const OtpFormHelp = ({ campaign, t }: OtpFormHelpProps) => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })

  if (isMobileWidth) {
    return (
      <Text
        as="span"
        textStyle="caption2"
        color="neutral.800"
        textAlign="center"
        paddingX="16px"
        maxWidth="400px"
        alignSelf="center"
      >
        {/* The space is intended */}
        {t('checkMobileNumber')}{' '}
        <LinkModal
          linkText={t('stillNotReceivingSms')}
          headerText={t('stillNotReceivingSms')}
          linkVariant="stillNotReceivingSms"
          closeButtonDDActionName={DDAction.CLOSE_SMS_NOT_RECEIVING_BUTTON}
          ddActionName={DDAction.STILL_NOT_RECEIVING_SMS_BUTTON}
          modalContent={
            <VStack
              align="start"
              divider={<Divider borderColor="neutral.300" />}
              spacing="24px"
              width="100%"
            >
              <Text textStyle="body1" color="neutral.800">
                {t('temporaryIssueWithSms', {
                  organiserName: campaign.organiserName,
                })}
              </Text>
              <NeedHelpInfoBox>
                <HelpInfoOrganiserContent campaign={campaign} />
              </NeedHelpInfoBox>
            </VStack>
          }
        />
      </Text>
    )
  }

  return (
    <VStack
      spacing="16px"
      textAlign="center"
      width="100%"
      marginTop="24px !important"
    >
      <Text textStyle="body1" color="neutral.800">
        {t('checkMobileNumber')}
      </Text>
      <LinkModal
        linkText={t('stillNotReceivingSms')}
        headerText={t('stillNotReceivingSms')}
        linkVariant="subhead"
        closeButtonDDActionName={DDAction.CLOSE_SMS_NOT_RECEIVING_BUTTON}
        ddActionName={DDAction.STILL_NOT_RECEIVING_SMS_BUTTON}
        modalContent={
          <VStack spacing="24px" align="start" width="100%">
            <Text textStyle="body1" color="neutral.800">
              {t('temporaryIssueWithSms', {
                organiserName: campaign.organiserName,
              })}
            </Text>
            <Divider borderColor="neutral.300" />
            <NeedHelpInfoBox>
              <HelpInfoOrganiserContent campaign={campaign} />
            </NeedHelpInfoBox>
          </VStack>
        }
      />
    </VStack>
  )
}
