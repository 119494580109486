import React, { CSSProperties, useState } from 'react'
import { Text, TextProps } from '@chakra-ui/react'

import useInterval from 'hooks/useInterval'

// Blink rate of cursor in ms
const BLINK_RATE = 530

type BlinkingCursorProps = {
  style?: CSSProperties | undefined
  textProps?: TextProps
}

const BlinkingCursor = ({ style, ...textProps }: BlinkingCursorProps) => {
  const [cursor, setCursor] = useState('|')

  useInterval(() => {
    setCursor((_cursor) => (_cursor ? '' : '|'))
  }, BLINK_RATE)

  return (
    <Text style={style} {...textProps}>
      {cursor}
    </Text>
  )
}

export default BlinkingCursor
