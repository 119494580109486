import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { DEFAULT_LOCALE, QUERY_STRING_LOCALE_KEY } from 'constants/translation'
import { changeLanguage } from 'utils/i18n'

const IGNORE_PATHS = ['/form', '/callback']

/**
 * Detects browser locale and sets language accordingly. Must be put within Router component
 */
export default function DetectDefaultLanguage(): JSX.Element {
  const history = useHistory()
  const { location } = history
  // If language does not exist in query string, then insert the browser's language
  // into the query string. If browser language is not avaiable, then the default (en)
  // is used.
  useEffect(() => {
    // Ignore paths that handle their own language logic.
    // e.g Pages that use Singpass callback and set language
    // based on returned state
    if (IGNORE_PATHS.includes(location.pathname)) {
      return
    }
    const query = queryString.parse(location.search)
    if (!query[QUERY_STRING_LOCALE_KEY]) {
      changeLanguage(navigator?.language || DEFAULT_LOCALE.code, history)
    }
  }, [history, location.search, location.pathname])
  return <></>
}
