import axios, { AxiosError } from 'axios'

import { CampaignDto } from 'constants/types'
import {
  BucketsData,
  ClaimedVoucherDataParams,
  MyInfoDataParams,
  MyinfoReceivedButNotEligibleError,
  SignupError,
} from 'errors/types'

interface SignupExceptionParams {
  type: SignupError
  campaign?: CampaignDto
  campaignId?: string
  claimedVoucherData?: ClaimedVoucherDataParams
  myinfoData?: MyInfoDataParams
  previousNonce?: string
  currentNonce?: string
  bucketsData?: BucketsData
}

// Error creator for throwing of errors
export class SignupException extends Error {
  type: SignupError
  campaign?: CampaignDto
  campaignId?: string
  claimedVoucherData?: ClaimedVoucherDataParams
  myinfoData?: MyInfoDataParams
  previousNonce?: string
  currentNonce?: string
  bucketsData?: BucketsData

  constructor({
    type,
    campaignId,
    claimedVoucherData,
    myinfoData,
    campaign,
    previousNonce,
    currentNonce,
    bucketsData,
  }: SignupExceptionParams) {
    super(type)
    this.type = type
    this.campaignId = campaignId
    this.claimedVoucherData = claimedVoucherData
    this.myinfoData = myinfoData
    this.campaign = campaign
    this.previousNonce = previousNonce
    this.currentNonce = currentNonce
    this.bucketsData = bucketsData
  }
}

export type ApiErrors = SignupException | AxiosError

// Helper functions
export function isSignupError(err: unknown): err is SignupException {
  return err instanceof SignupException
}

const receivedMyinfoButNotEligibleArray: SignupError[] = [
  'address_type_not_allowed',
  'buckets_evaluation_ineligible_claim',
  'claimed_voucher_for_address',
  'hdbtype_not_valid',
  'residential_status_not_citizen',
  'residential_status_not_citizen_or_pr',
  // Deprecate after buckets_evaluation_ineligible_claim is fully in prod
  'myinfo_attribute_not_verified',
  // Deprecate after buckets_evaluation_ineligible_claim is fully in prod
  'myinfo_attribute_not_available',
  'not_in_whitelist',
  'non_residential_address',
]

export function hasMyinfoButNotEligibleError(
  err: SignupError,
): err is MyinfoReceivedButNotEligibleError {
  return receivedMyinfoButNotEligibleArray.includes(err)
}

const NETWORK_ERROR = 'Network Error'

export function isNetworkError(err: unknown) {
  return axios.isAxiosError(err) && err.message === NETWORK_ERROR
}
