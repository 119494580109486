import { Trans, useTranslation } from 'react-i18next'
import { BiBuildings, BiMessageSquareDetail, BiPhone } from 'react-icons/bi'
import { Link, Text, VStack } from '@chakra-ui/react'

import { CampaignDto } from 'constants/types'
import { customMonitorClick } from 'utils/additionalMonitoring'
import { formatAreaCodeContact } from 'utils/helpers'
import { DDAction } from 'utils/monitoring'
import HelpInfoEntry from 'components/HelpInfo/HelpInfoEntry'

interface HelpInfoProps {
  campaign: CampaignDto
}

const HelpInfoOrganiserContent = ({ campaign }: HelpInfoProps) => {
  const { t } = useTranslation('contact')

  const {
    organiserName,
    organiserContactNumber,
    organiserFeedbackUrl,
    organiserLocation,
  } = campaign

  return (
    <VStack align="start" width="100%" spacing="8px">
      <Text textStyle="subhead2" color="neutral.800" textTransform="capitalize">
        {organiserName}
      </Text>
      <VStack
        align="flex-start"
        spacing="12px"
        textStyle="body2"
        color="neutral.800"
      >
        {organiserFeedbackUrl && (
          <HelpInfoEntry icon={BiMessageSquareDetail}>
            <Text textStyle="body2" color="neutral.800">
              <Trans t={t} i18nKey={'feedback'}>
                Submit a feedback form
                <Link
                  textStyle="subhead2"
                  href={organiserFeedbackUrl}
                  isExternal
                  onClick={() =>
                    customMonitorClick(DDAction.FEEDBACK_LINK_BUTTON)
                  }
                  textDecoration="underline"
                >
                  here
                </Link>
              </Trans>
            </Text>
          </HelpInfoEntry>
        )}
        {organiserContactNumber && (
          <HelpInfoEntry icon={BiPhone}>
            <Text textStyle="body2" color="neutral.800">
              <Trans t={t} i18nKey="contact">
                Call
                <Link
                  textStyle="subhead2"
                  href={`tel:+${organiserContactNumber}`}
                  onClick={() =>
                    customMonitorClick(DDAction.CONTACT_LINK_BUTTON)
                  }
                  textDecoration="underline"
                >
                  {{
                    organiserContactNumber: formatAreaCodeContact(
                      organiserContactNumber,
                    ),
                  }}
                </Link>
              </Trans>
            </Text>
          </HelpInfoEntry>
        )}
        {organiserLocation && (
          <HelpInfoEntry icon={BiBuildings}>
            <Text textStyle="body2" color="neutral.800">
              {organiserLocation}
            </Text>
          </HelpInfoEntry>
        )}
      </VStack>
    </VStack>
  )
}

export default HelpInfoOrganiserContent
