import { useMediaQuery, useTheme } from '@chakra-ui/react'
import { get } from '@chakra-ui/utils'

import { breakpoints } from 'theme/breakpoints'

export const useIsMobile = (): boolean => {
  const theme = useTheme()
  const mdBreakpoint = get(theme, 'breakpoints.md', breakpoints.md)
  const [isLargerThanMd] = useMediaQuery(`(min-width: ${mdBreakpoint})`)
  return !isLargerThanMd
}
