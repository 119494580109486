import { BiRightArrowAlt } from 'react-icons/bi'
import { Button, Text } from '@chakra-ui/react'

import { REDEEM_CONSTANTS } from 'constants/config'
import { CampaignDto } from 'constants/types'
import { formatDateString } from 'utils/helpers'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

import NeedHelpErrorComponent from '../components/NeedHelpErrorComponent'

interface CampaignEndedErrorProps extends ErrorProps {
  campaign?: CampaignDto
}

export const CampaignEndedError = ({
  t,
  campaign,
}: CampaignEndedErrorProps) => {
  return (
    <>
      <ErrorHeading
        title={t('campaignEnded.title', { campaignName: campaign?.name })}
        componentContent={
          <Text textStyle="body1" color="neutral.700">
            {t('campaignEnded.info', {
              expiryDate: formatDateString(campaign?.validityEnd),
            })}
          </Text>
        }
      />
      <Button
        as="a"
        isFullWidth
        href={REDEEM_CONSTANTS.campaignListingUrl}
        rightIcon={<BiRightArrowAlt />}
        children={t('buttons.goCampaignListingPage')}
      />
      {campaign && <NeedHelpErrorComponent campaign={campaign} />}
    </>
  )
}
