import { Trans } from 'react-i18next'
import { Link, Text, VStack } from '@chakra-ui/react'

import { EXTERNAL_CONSTANTS } from 'constants/config'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

export const MyinfoRecordsNotFoundError = ({ t }: ErrorProps) => (
  <ErrorHeading
    title={t('myinfoRecordsNotFound.title')}
    componentContent={
      <VStack spacing="24px" alignItems="left">
        <Text variant="bodyLight" id="error-content">
          <Trans>{t('myinfoRecordsNotFound.subtitle')}</Trans>
        </Text>
        <Text variant="bodyLight" id="error-content">
          <Trans
            t={t}
            i18nKey={'myinfoRecordsNotFound.info'}
            values={{
              singpassEmail: EXTERNAL_CONSTANTS.singpassSupportEmail,
            }}
          >
            Please contact Singpass at
            <Link
              href={`mailto:${EXTERNAL_CONSTANTS.singpassSupportEmail}`}
              variant="subhead"
            >
              {EXTERNAL_CONSTANTS.singpassSupportEmail}
            </Link>
          </Trans>
        </Text>
      </VStack>
    }
  />
)
