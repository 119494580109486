import { useTranslation } from 'react-i18next'
import { BiRightArrowAlt } from 'react-icons/bi'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import _ from 'lodash'

import ListingHeroImagePng from 'images/campaign-listing-hero.png'
import ListingHeroImageSvg from 'images/campaign-listing-hero.svg'
import { useGetListOfCampaigns } from 'hooks/Campaign'
import { customMonitorClick } from 'utils/additionalMonitoring'
import { DDAction } from 'utils/monitoring'
import { Footer } from 'components/Footer'
import { HeaderBar } from 'components/HeaderBar'

import { CampaignCardsContainer } from 'pages/CampaignListing/components/CampaignCardsContainer'
import { CampaignCardsLoading } from 'pages/CampaignListing/components/CampaignCardsLoading'
import { CampaignListingHeader } from 'pages/CampaignListing/components/CampaignListingHeader'
import { NoCampaignsContainer } from 'pages/CampaignListing/components/NoCampaignsContainer'

const LISTING_HEADER_IMAGE_HALF_WIDTH = 96

export const CampaignListingContainer = () => {
  const { t } = useTranslation('listing')
  const { data: campaignListingData, isLoading: isCampaignListingLoading } =
    useGetListOfCampaigns()

  return (
    <Flex
      minH="100vh"
      direction="column"
      backgroundColor="primary.100"
      overflow="hidden"
    >
      <Flex direction="column" minW="100%" align="center" flex={1}>
        <VStack
          backgroundColor="primary.700"
          width="100%"
          padding={{ base: '32px 24px 0px', lg: '94px 0px 0' }}
          alignItems="center"
        >
          <Flex flexDirection="column" w="100%" maxW="1144px" gap="40px">
            <HeaderBar variant="dark" />
            <HStack
              align="start"
              width="100%"
              justifyContent="space-between"
              position="relative"
              minH={{ base: '112px', lg: '230px' }}
            >
              <VStack
                align="start"
                paddingBottom="20px"
                minW={{
                  base: `calc(100% - ${LISTING_HEADER_IMAGE_HALF_WIDTH}px)`,
                }}
                maxW={{
                  base: `calc(100% - ${LISTING_HEADER_IMAGE_HALF_WIDTH}px)`,
                }}
              >
                <CampaignListingHeader t={t} />
              </VStack>
              <Box
                position="absolute"
                right={`-${LISTING_HEADER_IMAGE_HALF_WIDTH}px`}
                bottom={0}
                width={{
                  base: `${2 * LISTING_HEADER_IMAGE_HALF_WIDTH}px`,
                  lg: '400px',
                }}
                height={{ base: '112px', lg: '230px' }}
                minW={{
                  base: `${2 * LISTING_HEADER_IMAGE_HALF_WIDTH}px`,
                  lg: '400px',
                }}
                minH={{ base: '112px', lg: '230px' }}
              >
                <Image
                  height="100%"
                  width="100%"
                  src={ListingHeroImageSvg}
                  fallbackSrc={ListingHeroImagePng}
                />
              </Box>
            </HStack>
          </Flex>
        </VStack>
        {isCampaignListingLoading ? (
          <CampaignCardsLoading />
        ) : (
          [
            !campaignListingData || campaignListingData.length === 0 ? (
              <NoCampaignsContainer />
            ) : (
              <CampaignCardsContainer campaigns={campaignListingData} />
            ),
          ]
        )}

        {campaignListingData && !_.isEmpty(campaignListingData) && (
          <>
            <Link
              maxW="1144px"
              marginTop={{ base: '44px', lg: '64px' }}
              paddingX="24px"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              color="primary.500"
              onClick={() => {
                customMonitorClick(DDAction.LISTING_PAGE_FAQ_BUTTON)
              }}
              href="https://redeem.gov.sg/faq"
              cursor="pointer"
              display="flex"
              isExternal
            >
              <Text
                textStyle={{ base: 'subhead1', lg: 'h5' }}
                as="span"
                fontWeight={{ lg: 600 }}
              >
                {t('faq')}
              </Text>
              <Icon
                as={BiRightArrowAlt}
                boxSize={{ base: '14px', lg: '16px' }}
              />
            </Link>

            <Divider
              borderWidth="1px"
              borderColor="neutral.300"
              marginTop={{ base: '20px', lg: '32px' }}
              // Padding on both side is 16px, hence - 16px x 2
              width="calc(100% - 32px)"
              maxW="1144px"
            />
          </>
        )}
      </Flex>
      <Footer desktopPosition="relative" />
    </Flex>
  )
}
