import { AxiosError } from 'axios'
import _ from 'lodash'
import queryString from 'query-string'

import { MyInfoParams } from 'constants/types'
import {
  BucketsData,
  ClaimNotEligibleError,
  SignupErrorCode,
  SignupException,
} from 'errors'

export function isClientError(error: AxiosError | SignupException) {
  const errorCode = _.get(error, 'response.status')
  const signupErrorType = _.get(error, 'type')
  return (
    (errorCode >= 400 && errorCode < 500) ||
    (_.isString(signupErrorType) && signupErrorType !== 'service_unavailable')
  )
}

export function isNotFoundError(error: AxiosError | SignupException) {
  const errorCode = _.get(error, 'response.status')
  return errorCode === 404
}

export function isBadRequestError(error: AxiosError | SignupException) {
  const errorCode = _.get(error, 'response.status')
  return errorCode === 400
}

export function isTooManyRequestsError(error: AxiosError | SignupException) {
  const errorCode = _.get(error, 'response.status')
  return errorCode === 429
}

// TODO: find a better way than any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractBucketsDataFromErrorObj(errorObject: any): BucketsData {
  return {
    expected: errorObject?.expected || [],
    displayAttribute: errorObject?.displayAttribute || '',
    attribute: errorObject?.attribute,
    valid: errorObject?.valid || false,
  }
}

export function transformAxiosError(
  err: AxiosError,
): SignupException | AxiosError {
  if (err.response) {
    const errorObj = err.response.data.error

    if (
      errorObj.code &&
      Object.values(SignupErrorCode).includes(errorObj.code)
    ) {
      // Handle claim ineligible errors
      if (errorObj.code === SignupErrorCode.CLAIM_INELIGIBLE) {
        return new SignupException({
          type: errorObj.data.category as ClaimNotEligibleError,
          myinfoData: errorObj.data,
          bucketsData: extractBucketsDataFromErrorObj(errorObj.data),
        })
      }
      // Handle claimed vouchers error
      if (errorObj.code === SignupErrorCode.CLAIMED_VOUCHER_FOR_ADDRESS) {
        return new SignupException({
          type: 'claimed_voucher_for_address',
          claimedVoucherData: errorObj.data,
        })
      } // Handle campaign not started error
      if (errorObj.code === SignupErrorCode.CAMPAIGN_NOT_STARTED) {
        return new SignupException({
          type: 'campaign_not_started',
          campaign: errorObj.data.campaign,
        })
      }
      // Handle campaign expired error
      if (errorObj.code === SignupErrorCode.CAMPAIGN_ENDED) {
        return new SignupException({
          type: 'campaign_ended',
          campaign: errorObj.data.campaign,
        })
      }
      // Handle other errors
      return new SignupException({
        type: errorObj.code,
      })
    }
    if (err.response.status >= 500) {
      return new SignupException({
        type: 'service_unavailable',
      })
    }
  }

  return err
}

export function parseMyInfoRedirect(myInfoQueryString: string) {
  const {
    state: stateParams,
    code: authCode,
    error: myInfoError,
  }: MyInfoParams = queryString.parse(myInfoQueryString)

  // Check if missing params within redirect
  if (!stateParams) {
    throw new SignupException({
      type: 'myinfo_redirect_error',
    })
  }

  // State params are delimited by colons
  const [nonce, retrievedCampaignId, localeCode] = stateParams.split(':')

  // Check if myInfo redirects back with an error
  if (myInfoError) {
    throw new SignupException({
      type: 'myinfo_access_denied',
      campaignId: retrievedCampaignId,
    })
  }

  if (!authCode) {
    throw new SignupException({
      type: 'myinfo_redirect_error',
      campaignId: retrievedCampaignId,
    })
  }

  return {
    nonce,
    retrievedCampaignId,
    localeCode,
    authCode,
  }
}
