import { TFunction } from 'react-i18next'
import { Text } from '@chakra-ui/react'

interface CampaignListingHeaderProps {
  t: TFunction<'listing', undefined>
}

export const CampaignListingHeader = ({ t }: CampaignListingHeaderProps) => {
  return (
    <>
      <Text
        // Custom styling
        textStyle={{ base: 'mobileDisplay1', lg: 'display1' }}
        color="primary.100"
        wordBreak="break-word"
      >
        {t('listingTitle')}
      </Text>
      <Text
        marginTop="8px"
        textStyle={{ base: 'body1', lg: 'h4' }}
        color="primary.200"
        wordBreak="break-word"
      >
        {t('listingSubheading')}
      </Text>
    </>
  )
}
