import { Spacer, Text, VStack } from '@chakra-ui/react'

import { REDEEM_CONSTANTS } from 'constants/config'
import { ActionLink } from 'components/ActionLink'

import { RedeemContactDetails } from 'pages/ErrorBoundary/components/AdditionalDetails'
import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'

import { ErrorProps } from './types'

export const CampaignNotReadyError = ({ t }: ErrorProps) => (
  <>
    <ErrorHeading
      title={t('notReadyCampaign.title')}
      componentContent={
        <VStack align="stretch" spacing="32px">
          <Text variant="bodyLight">{t('notReadyCampaign.info')}</Text>
          <RedeemContactDetails t={t} />
        </VStack>
      }
    />
    {/* Additional spacing (24px) for actionable links*/}
    <Spacer />
    <ActionLink href={REDEEM_CONSTANTS.landingPage}>
      {t('buttons.goMainPage')}
    </ActionLink>
  </>
)
