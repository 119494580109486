import { Divider, Text, VStack } from '@chakra-ui/react'

type TemplateErrorProps = {
  titleText: string
  infoText?: string
  helpInfoBox?: React.ReactNode
}

const TemplateError = ({
  titleText,
  infoText,
  helpInfoBox,
}: TemplateErrorProps) => {
  return (
    <VStack
      divider={<Divider borderColor="neutral.300" />}
      spacing="24px"
      align="start"
    >
      <VStack spacing="16px" align="start">
        <Text textStyle="h4" color="neutral.900" id="error-header">
          {titleText}
        </Text>
        {infoText && (
          <Text textStyle="body1" color="neutral.700" id="error-header-info">
            {infoText}
          </Text>
        )}
      </VStack>
      {helpInfoBox && helpInfoBox}
    </VStack>
  )
}

export default TemplateError
