import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BiRightArrowAlt } from 'react-icons/bi'
import {
  Badge,
  Box,
  Button,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import redeemLogoNoWordsPng from 'images/redeem-logo-no-words.png'
import redeemLogoNoWordsSvg from 'images/redeem-logo-no-words.svg'
import { ddAddCustomAction } from 'utils/additionalMonitoring'
import { formatDateString } from 'utils/helpers'
import { DDAction } from 'utils/monitoring'

interface CampaignCardProps {
  id: string
  title: string
  voucherTotalAmount: number
  validityStart?: string | null
  validityEnd?: string | null
  size?: 'sm' | 'lg'
  isNew?: boolean
  logoUrl: string | null
}

export const CampaignCard: FC<CampaignCardProps> = ({
  size,
  id,
  title,
  voucherTotalAmount,
  validityStart,
  validityEnd,
  isNew = false,
  logoUrl,
}) => {
  const styles = useMultiStyleConfig('CampaignCard', { size })
  const { t, i18n } = useTranslation('listing')

  return (
    <LinkBox sx={styles.container}>
      <HStack spacing="12px" align="center">
        <Image
          maxHeight={{ base: '14px', lg: '28px' }}
          src={logoUrl || redeemLogoNoWordsSvg}
          fallbackSrc={redeemLogoNoWordsPng}
          alt="Organisation Logo"
        />
        {isNew && <Badge sx={styles.label}>{t('campaignCard.new')}!</Badge>}
      </HStack>
      <Text sx={styles.title}>{title}</Text>
      <Text sx={styles.description}>
        {t('campaignCard.description', { voucherTotalAmount })}
      </Text>
      <HStack justify="space-between" alignItems="flex-start" marginTop="8px">
        <Box padding="4px 8px" borderRadius="4px" backgroundColor="primary.100">
          {/* TODO: implement translations for no expiry date*/}
          <Text sx={styles.useByDate}>
            {validityStart && validityEnd
              ? `${formatDateString(validityStart)} - ${formatDateString(
                  validityEnd,
                )}`
              : 'No expiry date'}
          </Text>
        </Box>
        <LinkOverlay
          href={`/${id}?lang=${i18n.language}`}
          onClick={() =>
            ddAddCustomAction({
              actionName: DDAction.SELECT_CAMPAIGN_FROM_LISTING,
              context: {
                campaignId: id,
                campaignName: title,
              },
            })
          }
        >
          <Button
            sx={styles.button}
            rightIcon={<BiRightArrowAlt />}
            iconSpacing={0}
          />
        </LinkOverlay>
      </HStack>
    </LinkBox>
  )
}
