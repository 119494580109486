import { useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useAuth } from 'context/AuthContext'

import { FormPhase, PersonResponse } from 'constants/types'
import { isSignupError, SignupException } from 'errors'
import decodePerson from 'utils/decodePerson'
import { changeLanguage } from 'utils/i18n'
import { parseMyInfoRedirect } from 'utils/service'
import { retrievePersonDetails } from 'services/RedeemApi'
import {
  clearSessionStorage,
  isNonceMatch,
  retrieveNonce,
} from 'services/Storage'

export function usePersonCallback() {
  const history = useHistory()
  const handleError = useErrorHandler()

  const { person, setFullPerson, setFormPhase } = useAuth()
  const [campaignId, setCampaignId] = useState('')

  const { mutate: retrievePerson, isLoading } = useMutation<
    PersonResponse,
    AxiosError,
    { authCode: string; campaignId: string }
  >(
    ['person'],
    ({ authCode, campaignId }) => retrievePersonDetails(authCode, campaignId),
    {
      onSuccess: async (data) => {
        setFullPerson({
          ...decodePerson(data.personJwt),
          personJwt: data.personJwt,
          isExisting: data.isExisting,
        })

        setFormPhase(FormPhase.SINGPASS_AUTHENTICATED)
      },

      onError: (err: unknown, variables) => {
        // Middleware for attaching campaignId to signupErrors coming from callback
        // This is done as error page has no access to campaign context
        if (isSignupError(err)) {
          handleError(
            new SignupException({
              campaignId: variables.campaignId,
              type: err.type,
              claimedVoucherData: err.claimedVoucherData,
              myinfoData: err.myinfoData,
              bucketsData: err.bucketsData,
            }),
          )
        }
      },
      // Defaults all errors to just throw to nearest error boundary
      useErrorBoundary: true,
    },
  )

  // On mount, check certain conditions are met to qualify if request is necessary
  useEffect(() => {
    const { nonce, retrievedCampaignId, localeCode, authCode } =
      parseMyInfoRedirect(history.location.search)

    // setting campaign id to trigger dependencies using campaignId
    setCampaignId(retrievedCampaignId)

    // Set locale code if specified in redirect params
    if (localeCode) {
      changeLanguage(localeCode, history)
    }

    // Presence of person indicates a navigation via browser back history
    // mutation is not required as personJwt is already retrieved
    if (!person) {
      if (!nonce || !retrievedCampaignId || !isNonceMatch(nonce)) {
        throw new SignupException({
          type: 'myinfo_redirect_error',
          campaignId: retrievedCampaignId,
          previousNonce: retrieveNonce() ?? '',
          currentNonce: nonce,
        })
      }

      // Clears nonce before a retrieve request is being made
      // Prevents double retrieve on refresh
      clearSessionStorage()
      retrievePerson({ authCode, campaignId: retrievedCampaignId })
    }
  }, [history, person, retrievePerson])

  return { personData: person, isLoading, campaignId }
}
