import { Trans, useTranslation } from 'react-i18next'
import { HStack, Text, useBreakpointValue, VStack } from '@chakra-ui/react'

import { ReactComponent as QuestionMark } from 'images/question-mark.svg'

type HelpInfoBoxProps = {
  headeri18nKey: 'needHelpHeading' | 'moreHelpHeading'
  headerBackgroundColor: 'primary.200' | 'primary.100'
  children: React.ReactNode
}

const HelpInfoBox = ({
  children,
  headerBackgroundColor,
  headeri18nKey,
}: HelpInfoBoxProps) => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })
  const { t } = useTranslation('contact')

  return (
    <VStack
      width="100%"
      spacing="0px"
      borderRadius="4px 4px 4px 4px"
      borderColor="primary.200"
      borderWidth="1px"
      overflow="hidden"
    >
      <HStack
        backgroundColor={headerBackgroundColor}
        width="100%"
        padding={isMobileWidth ? '8px 16px' : '12px 20px'}
        spacing="8px"
        borderBottom="1px solid"
        borderColor="primary.200"
        align="center"
      >
        <QuestionMark color="#505798" width="16px" height="16px" />
        <Text textStyle="subhead2" textColor="primary.500">
          <Trans t={t} i18nKey={headeri18nKey} />
        </Text>
      </HStack>
      <VStack
        align="start"
        padding={isMobileWidth ? '16px' : '16px 20px'}
        width="100%"
      >
        {children}
      </VStack>
    </VStack>
  )
}

export default HelpInfoBox
