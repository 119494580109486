import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VStack } from '@chakra-ui/react'
import type { ClaimedVoucherDataParams, MyInfoDataParams } from 'errors'

import type { CampaignDto, Person } from 'constants/types'
import useScrollToTopOnRender from 'hooks/useScrollToTopOnRender'
import DetailsLayout from 'components/DetailsLayout'
import { DisplayContainer } from 'components/DisplayContainer'
import { HeaderBar } from 'components/HeaderBar'

type MyinfoShownBeforeErrorBoundaryProps = {
  campaignData: CampaignDto | undefined
  myinfoData?: MyInfoDataParams
  children: React.ReactNode
  claimedVoucherData?: ClaimedVoucherDataParams
}

const MyinfoShownBeforeErrorBoundary = ({
  campaignData,
  myinfoData,
  children,
  claimedVoucherData,
}: MyinfoShownBeforeErrorBoundaryProps) => {
  useScrollToTopOnRender()
  const { t } = useTranslation('callback')
  const [showChildren, setShowChildren] = useState(false)

  // myinfo Data is not fixed obejct structure, thus we have to do all these explicit casting
  const person: Person = {
    name: (myinfoData?.name ?? claimedVoucherData?.name ?? '') as string,
    nric: (myinfoData?.nric ?? claimedVoucherData?.nric ?? '') as string,
    block: (myinfoData?.block ?? claimedVoucherData?.block ?? '') as string,
    postalCode: (myinfoData?.postalCode ??
      claimedVoucherData?.postalCode ??
      '') as string,
    unit: (myinfoData?.unit ?? claimedVoucherData?.unit ?? '') as string,
    floor: (myinfoData?.floor ?? claimedVoucherData?.floor ?? '') as string,
    street: (myinfoData?.street ?? claimedVoucherData?.street ?? '') as string,
    invalidAddress: (myinfoData?.invalidAddress ?? '') as string,
  }

  return (
    <>
      {showChildren ? (
        children
      ) : (
        <DisplayContainer>
          <HeaderBar logoImageSrc={campaignData?.logoUrl} />
          <VStack align="stretch" spacing={{ base: '24px', lg: '16px' }}>
            <DetailsLayout
              person={person}
              title={t('confirmDetails')}
              onPrimaryClick={() => setShowChildren(true)}
            />
          </VStack>
        </DisplayContainer>
      )}
    </>
  )
}

export default MyinfoShownBeforeErrorBoundary
