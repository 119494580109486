import { useTranslation } from 'react-i18next'
import { Text, VStack } from '@chakra-ui/react'

import { formatAddress } from 'utils/helpers'

import DetailsInfoBox from './DetailsInfoBox'
import { DetailsPaneProps } from './types'

export const DetailsPane = ({
  street,
  block,
  postalCode,
  unit,
  floor,
  name,
  nric,
  invalidAddress,
}: DetailsPaneProps) => {
  const { t } = useTranslation('callback')

  const address = invalidAddress
    ? invalidAddress
    : formatAddress({
        block,
        street,
        postalCode,
        unit,
        floor,
      })

  return (
    <VStack align="stretch" spacing={{ base: '8px', lg: '16px' }}>
      <DetailsInfoBox
        id="vouchers-for-pane"
        titleText={t('vouchersFor')}
        content={
          <VStack padding="16px 18px" spacing="2px" align="start">
            <Text textStyle="caption1" color="neutral.500">
              {t('address')}
            </Text>
            <Text
              textStyle="subhead1"
              color="neutral.800"
              textTransform="capitalize"
            >
              {address}
            </Text>
          </VStack>
        }
      />

      <DetailsInfoBox
        id="claimed-by-pane"
        titleText={t('claimedBy')}
        content={
          <VStack padding="16px 18px" spacing="16px" align="start">
            <VStack spacing="2px" align="start">
              <Text textStyle="caption1" color="neutral.500">
                {t('name')}
              </Text>
              <Text
                textStyle="subhead1"
                color="neutral.800"
                textTransform="capitalize"
              >
                {name?.toLowerCase()}
              </Text>
            </VStack>
            {nric && (
              <VStack spacing="2px" align="start">
                <Text textStyle="caption1" color="neutral.500">
                  {t('nric')}
                </Text>
                <Text
                  textStyle="subhead1"
                  color="neutral.800"
                  textTransform="capitalize"
                  data-dd-privacy="mask"
                >
                  {nric}
                </Text>
              </VStack>
            )}
          </VStack>
        }
      />
    </VStack>
  )
}
