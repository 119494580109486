import { FC } from 'react'
import { Box, Text } from '@chakra-ui/react'

interface HeadingProps {
  title: string
  content?: string
  isBolded?: boolean
  id?: string
}

export const Heading: FC<HeadingProps> = ({
  title,
  content,
  isBolded = false,
  id,
}) => {
  return (
    <Box>
      <Text variant={isBolded ? 'boldTitle' : 'title'} id={id}>
        {title}
      </Text>
      {content && (
        <Text variant="bodyLight" mt={{ base: '8px', lg: '16px' }}>
          {content}
        </Text>
      )}
    </Box>
  )
}
