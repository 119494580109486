import { FC, ReactNode } from 'react'
import { Box, Text, VStack } from '@chakra-ui/react'

import { Heading } from 'components/Heading'

interface ErrorHeadingProps {
  title: string
  textContent?: ReactNode
  componentContent?: ReactNode
}

// TODO: Remove this component. Generalise the heading component to take into 2 different variants for different margins

export const ErrorHeading: FC<ErrorHeadingProps> = ({
  title,
  textContent,
  componentContent,
}) => {
  return (
    <VStack spacing="16px" align="stretch">
      <Heading title={title} id="error-header" isBolded />
      {textContent && (
        <Text variant="bodyLight" id="error-content">
          {textContent}
        </Text>
      )}

      {componentContent && !textContent && (
        <Box id="error-content" width="100%">
          {componentContent}
        </Box>
      )}
    </VStack>
  )
}
