import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { CampaignProvider } from 'context/CampaignContext'

import { isLaunchDarklySdkClientAvailable } from 'constants/config'
import { FormPhase } from 'constants/types'
import NEAFeatureFlagNotificationBanner from 'components/NEAFeatureFlagNotificationBanner/NEAFeatureFlagNotificationBanner'
import { PrivateCampaignRoute } from 'components/PrivateCampaignRoute'

import { DetailsPage } from 'pages/Details/DetailsPage'
import { LoginPageContainer } from 'pages/Login/LoginPageContainer'
import { OtpFormPage } from 'pages/OtpForm'
import { PhoneFormPageContainer } from 'pages/PhoneForm/PhoneFormPageContainer'
import { SuccessPageContainer } from 'pages/Success/SuccessPageContainer'

const CampaignRouter = () => {
  const { campaignId } = useParams<{ campaignId: string }>()
  const { path } = useRouteMatch()

  const isNEACampaign =
    campaignId === 'campaign_b045896f-b662-4cc1-876c-4b6cf6f541b3'

  return (
    <CampaignProvider campaignId={campaignId}>
      {isNEACampaign && isLaunchDarklySdkClientAvailable && (
        <NEAFeatureFlagNotificationBanner />
      )}
      <Switch>
        <PrivateCampaignRoute
          path={`${path}/form/details`}
          allowedFormPhase={FormPhase.SINGPASS_AUTHENTICATED}
        >
          <DetailsPage />
        </PrivateCampaignRoute>
        <PrivateCampaignRoute
          path={`${path}/form/register`}
          allowedFormPhase={FormPhase.ENTER_NUMBER}
        >
          <PhoneFormPageContainer />
        </PrivateCampaignRoute>
        <PrivateCampaignRoute
          path={`${path}/form/verify`}
          allowedFormPhase={FormPhase.ENTER_OTP}
        >
          <OtpFormPage />
        </PrivateCampaignRoute>
        <PrivateCampaignRoute
          path={`${path}/voucher`}
          allowedFormPhase={FormPhase.SUCCESS}
        >
          <SuccessPageContainer />
        </PrivateCampaignRoute>

        <Route path="*">
          <LoginPageContainer />
        </Route>
      </Switch>
    </CampaignProvider>
  )
}

export default CampaignRouter
