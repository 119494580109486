import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

import { CampaignDto } from 'constants/types'
import { DDAction } from 'utils/monitoring'
import HelpInfoOrganiserContent from 'components/HelpInfo/HelpInfoOrganiserContent'
import LinkModal from 'components/LinkModal'

const NeedHelpButton = ({ campaign }: { campaign: CampaignDto }) => {
  const { t } = useTranslation(['common', 'contact'])

  return (
    <Box alignSelf="center">
      {/* Previously there isn't a desktop version for this. Keeping this consistent first */}
      <LinkModal
        linkText={t('common:needHelp')}
        headerText={t('contact:moreHelpHeading')}
        linkVariant="subhead"
        ddActionName={DDAction.NEED_HELP_BUTTON_ERROR}
        closeButtonDDActionName={DDAction.CLOSE_NEED_HELP_BUTTON_ERROR}
        modalContent={<HelpInfoOrganiserContent campaign={campaign} />}
      />
    </Box>
  )
}

export default NeedHelpButton
