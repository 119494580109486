import { useTranslation } from 'react-i18next'
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'

import { LinkVariants } from 'theme/components/Link'

type LinkModalProps = {
  linkVariant: LinkVariants
  ddActionName: string
  closeButtonDDActionName: string
  modalContent: React.ReactNode
  linkText?: string
  headerText: React.ReactNode
}

// TODO: in the future if there is a need for more link and close button name, consider abstracting out as props
// NOTE: A drawer and a normal desktop modal is also a type of modal
const LinkModal = ({
  linkVariant,
  ddActionName,
  modalContent,
  linkText,
  closeButtonDDActionName,
  headerText,
}: LinkModalProps) => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation('common')

  return (
    <>
      <Link
        onClick={onOpen}
        data-dd-action-name={ddActionName}
        textDecoration={linkVariant === 'body' ? 'none' : 'underline'}
        variant={linkVariant}
      >
        {linkText}
      </Link>
      {isMobileWidth ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement="bottom"
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent width="100%" padding="32px" borderTopRadius="32px">
            <Text textStyle="h3" color="primary.700" marginBottom="16px">
              {headerText}
            </Text>
            {modalContent}
            <Button
              marginTop="24px"
              id="close-modal-button"
              onClick={onClose}
              variant="primary"
              data-dd-action-name={closeButtonDDActionName}
            >
              {t('close')}
            </Button>
          </DrawerContent>
        </Drawer>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} isCentered autoFocus={false}>
          <ModalOverlay />
          <ModalContent
            padding="32px"
            minWidth="680px"
            boxShadow="0px 0px 10px rgba(216, 222, 235, 0.5)"
            borderRadius="4px"
          >
            <Text textStyle="h3" color="primary.700" marginBottom="16px">
              {headerText}
            </Text>
            <ModalCloseButton
              id="close-modal-button"
              data-dd-action-name={closeButtonDDActionName}
            />
            {modalContent}
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default LinkModal
