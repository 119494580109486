import { FC } from 'react'
import {
  ComponentWithAs,
  forwardRef,
  Icon,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Text,
  useStyleConfig,
} from '@chakra-ui/react'

import { BiLinkExternal } from 'components/icons'

interface RestrictedGovtMastheadLinkProps extends ChakraLinkProps {
  externalLinkIcon?: React.ReactElement
  isDisabled?: boolean
}

type RestrictedGovtMastheadLinkWithParts = ComponentWithAs<
  'a',
  RestrictedGovtMastheadLinkProps
> & {
  ExternalIcon: typeof RestrictedGovtMastheadExternalIcon
}

export const RestrictedGovtMastheadLink = forwardRef<
  RestrictedGovtMastheadLinkProps,
  'a'
>(
  (
    {
      externalLinkIcon = <RestrictedGovtMastheadLink.ExternalIcon />,
      isDisabled,
      children,
      ...props
    },
    ref,
  ) => {
    const styles = useStyleConfig('Link', props)

    if (isDisabled) {
      return (
        <Text
          as="a"
          sx={props.sx ?? styles}
          alignItems="center"
          aria-disabled
          ref={ref}
        >
          {children}
          {props.isExternal && externalLinkIcon}
        </Text>
      )
    }

    return (
      <ChakraLink
        alignItems="center"
        display="inline-flex"
        {...props}
        ref={ref}
      >
        {children}
        {props.isExternal && externalLinkIcon}
      </ChakraLink>
    )
  },
) as RestrictedGovtMastheadLinkWithParts

const RestrictedGovtMastheadExternalIcon: FC = (): JSX.Element => {
  return (
    <Icon
      as={BiLinkExternal}
      verticalAlign="middle"
      marginLeft="0.25rem"
      aria-hidden
    />
  )
}

RestrictedGovtMastheadLink.displayName = 'RestrictedGovtMastheadLink'

RestrictedGovtMastheadLink.ExternalIcon = RestrictedGovtMastheadExternalIcon
RestrictedGovtMastheadLink.ExternalIcon.displayName =
  'RestrictedGovtMastheadLink.RestrictedGovtMastheadExternalIcon'
