import { useErrorHandler } from 'react-error-boundary'
import { useMutation } from 'react-query'

import {
  ApiErrors,
  isNetworkError,
  isSignupError,
  SignupException,
} from 'errors'
import { registerOtp, verifyOtp } from 'services/RedeemApi'

type RegisterOtpRequest = {
  phone: string
  campaignId: string
  lang: string
}

export function useRegisterOtp() {
  const handleError = useErrorHandler()
  const { mutate, isLoading } = useMutation<
    void,
    ApiErrors,
    RegisterOtpRequest
  >(
    ({ phone, campaignId, lang }) =>
      registerOtp({ contactNumber: phone, campaignId, lang }),
    {
      onError: (err: unknown, variables) => {
        if (isNetworkError(err)) {
          handleError(
            new SignupException({
              type: 'network_error',
              campaignId: variables.campaignId,
            }),
          )
        }
      },
      // Propagate SignupExceptions to error boundary
      useErrorBoundary: (err) => {
        return isSignupError(err)
      },
    },
  )

  return {
    mutate,
    isLoading,
  }
}

type VerifyOtpRequest = {
  contactNumber: string
  otp: string
  campaignId: string
}

export function useVerifyOtp() {
  const handleError = useErrorHandler()
  const { mutateAsync, isLoading } = useMutation<
    string,
    ApiErrors,
    VerifyOtpRequest
  >(({ contactNumber, otp }) => verifyOtp(contactNumber, otp), {
    onError: (err: unknown, variables) => {
      if (isNetworkError(err)) {
        handleError(
          new SignupException({
            type: 'network_error',
            campaignId: variables.campaignId,
          }),
        )
      }
    },
    // Propagate SignupExceptions to error boundary
    useErrorBoundary: (err) => isSignupError(err),
  })

  return {
    mutateAsync,
    isLoading,
  }
}
