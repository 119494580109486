import { TFunction, Trans } from 'react-i18next'
import { Link, Text } from '@chakra-ui/react'

import { REDEEM_CONSTANTS } from 'constants/config'
import { stripUrlScheme } from 'utils/helpers'

interface ErrorTransProps {
  t: TFunction<'error', undefined>
}

export const CampaignListingDetails = ({ t }: ErrorTransProps) => (
  <Text variant="bodyLight">
    <Trans t={t} i18nKey={'pageNotFound.info'}>
      To find and claim government vouchers you are eligible for, visit
      <Link href={REDEEM_CONSTANTS.campaignListingUrl} variant="subhead">
        {{
          campaignListingUrl: stripUrlScheme(
            REDEEM_CONSTANTS.campaignListingUrl,
          ),
        }}
      </Link>
    </Trans>
  </Text>
)

export const RedeemContactDetails = ({ t }: ErrorTransProps) => (
  <Text variant="bodyLight">
    <Trans t={t} i18nKey={'additionalInfo'}>
      For more information, please write to
      <Link href={`mailto:${REDEEM_CONSTANTS.supportEmail}`} variant="subhead">
        feedback@redeem.gov.sg
      </Link>
    </Trans>
  </Text>
)

// TODO: Add translations
export const RedeemContactDetailsWithScreenshot = () => (
  <Text variant="bodyLight">
    To help us find out more about what went wrong, please take a screenshot of
    this screen and email it to{' '}
    <Link href={`mailto:${REDEEM_CONSTANTS.supportEmail}`} variant="subhead">
      feedback@redeem.gov.sg
    </Link>
    .
  </Text>
)
