import { FC } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { VStack } from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { useCampaignContext } from 'context/CampaignContext'

import { FormPhase } from 'constants/types'
import { SignupException } from 'errors'
import { DisplayContainer } from 'components/DisplayContainer'
import { HeaderBar } from 'components/HeaderBar'

interface PrivateRouteProps extends RouteProps {
  allowedFormPhase: FormPhase
}

export const PrivateCampaignRoute: FC<PrivateRouteProps> = ({
  children,
  component: Component,
  allowedFormPhase,
  ...rest
}) => {
  if (!children && !Component)
    throw new Error('Either children, component is required.')

  const { verifyAllowedRouteState } = useAuth()
  const { routeCampaignId, campaign } = useCampaignContext()

  const renderRoute2 = (props: RouteComponentProps) => {
    if (!verifyAllowedRouteState(allowedFormPhase) || !campaign) {
      throw new SignupException({
        type: 'unauthorised_route_error',
        campaignId: routeCampaignId,
      })
    }
    return (
      <DisplayContainer>
        <HeaderBar logoImageSrc={campaign?.logoUrl} />
        <VStack align="stretch" spacing={{ base: '24px', lg: '16px' }}>
          {children || (Component && <Component {...props} />)}
        </VStack>
      </DisplayContainer>
    )
  }

  return <Route {...rest} render={renderRoute2} />
}
