import { MYINFO } from 'constants/config'

import { generateNonce } from './Storage'

export function authorise({
  campaign,
  localeCode,
  singpassEserviceId,
}: {
  campaign: string
  localeCode?: string | null
  singpassEserviceId: string
}): void {
  // Singpass API does not play well with query strings. Callback URL
  // must be an exact match with those registered under Singpass panel.
  // Registering a new URL with Singpass for every possible language
  // is not scalable.
  const state = [generateNonce(), campaign, localeCode].join(':')
  const authoriseUrl =
    MYINFO.authUrl +
    `?client_id=${MYINFO.clientId}` +
    `&attributes=${MYINFO.scopes}` +
    `&purpose=${MYINFO.purpose}` +
    `&state=${state}` +
    `&redirect_uri=${MYINFO.redirectUrl}` +
    `&sp_esvcId=${singpassEserviceId}`
  // Timeout is required as window.location.href doesn't work on old chrome browsers
  // https://www.codeproject.com/Answers/425242/window-location-href-not-working-in-Chrome
  setTimeout(() => {
    window.location.href = encodeURI(authoriseUrl)
  }, 200)
}
