import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ChakraProvider } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import { AppRouter } from 'app/AppRouter'
import { AuthProvider } from 'context/AuthContext'

import { theme } from 'theme'
import { DEFAULT_LOCALE } from 'constants/translation'
import { useMonitorLanguage } from 'utils/additionalMonitoring'
import { initTranslations } from 'utils/i18n'
import initMonitoringIfTokensPresent from 'utils/monitoring'
import { initTimezone } from 'utils/timezone'

initTimezone()
initTranslations()
initMonitoringIfTokensPresent()

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`

const queryClient = new QueryClient()

function App(): JSX.Element {
  const { i18n: i18nInstance } = useTranslation()

  const language =
    i18nInstance?.language?.substring(0, 2) || DEFAULT_LOCALE.code // Get base language locale
  useMonitorLanguage(language)

  return (
    <QueryClientProvider client={queryClient}>
      {/* Devtools auto excluded for prod */}
      <ReactQueryDevtools initialIsOpen={false} />
      <ChakraProvider theme={theme}>
        <Global styles={GlobalStyles} />
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </ChakraProvider>
    </QueryClientProvider>
  )
}

export default App
