import _ from 'lodash'

type TMapKeys =
  | 'REDEEM_API_URL'
  | 'MYINFO_AUTH_URL'
  | 'MYINFO_REDIRECT_URL'
  | 'LISTING_URL'
type TEnv = 'production' | 'staging' | 'experimental' | 'vapt' | 'development'
const env: TEnv =
  (process.env.REACT_APP_ENV as TEnv | undefined) ?? 'development'
const URL_CONSTANTS_MAP: Record<TMapKeys, Record<TEnv, string>> = {
  REDEEM_API_URL: {
    production: 'https://api-cdc.redeem.gov.sg/v1',
    staging: 'https://api-cdc-staging.redeem.gov.sg/v1',
    experimental: 'https://api-cdc-staging.redeem.gov.sg/v1',
    vapt: 'https://api-vapt.redeem.gov.sg/v1',
    development: 'http://localhost:10000/v1',
  },
  MYINFO_AUTH_URL: {
    production: 'https://api.myinfo.gov.sg/gov/v3/authorise',
    staging: 'https://staging.api.myinfo.gov.sg/gov/v3/authorise',
    experimental: 'https://mockpass-staging.redeem.gov.sg/myinfo/v3/authorise',
    vapt: 'https://mockpass-staging.redeem.gov.sg/myinfo/v3/authorise',
    development: 'https://mockpass-staging.redeem.gov.sg/myinfo/v3/authorise',
  },
  MYINFO_REDIRECT_URL: {
    production: 'https://signup.redeem.gov.sg/form',
    staging: 'https://signup-staging.redeem.gov.sg/form',
    experimental: 'https://signup-staging.redeem.gov.sg/form',
    vapt: 'https://signup-vapt.redeem.gov.sg/form',
    development: 'http://localhost:3001/callback',
  },
  LISTING_URL: {
    production: 'https://signup.redeem.gov.sg',
    staging: 'https://signup-staging.redeem.gov.sg',
    experimental: 'https://signup-staging.redeem.gov.sg',
    vapt: 'https://signup-vapt.redeem.gov.sg',
    development: 'http://localhost:3001',
  },
}

export const REDEEM_API_URL = URL_CONSTANTS_MAP['REDEEM_API_URL'][env]
const MYINFO_AUTH_URL = URL_CONSTANTS_MAP['MYINFO_AUTH_URL'][env]
const MYINFO_CLIENT_ID =
  process.env.REACT_APP_ENV === 'production'
    ? 'PROD-T16GB0002G-MYINFOTUO-REDEEMSG'
    : 'STG-T16GB0002G-MYINFOTUO-REDEEMSG'
const MYINFO_REDIRECT_URL = URL_CONSTANTS_MAP['MYINFO_REDIRECT_URL'][env]
// TODO: Deprecate env specific usages once new mocks are developed
// To use relative root pathing via `/` instead
const LISTING_URL = URL_CONSTANTS_MAP['LISTING_URL'][env]
const MYINFO_SCOPES = 'uinfin,name,regadd,residentialstatus,housingtype,hdbtype'
const MYINFO_PURPOSE = 'Voucher Redemption'

export const MYINFO = {
  authUrl: MYINFO_AUTH_URL,
  clientId: MYINFO_CLIENT_ID,
  redirectUrl: MYINFO_REDIRECT_URL,
  scopes: MYINFO_SCOPES,
  purpose: MYINFO_PURPOSE,
}

export const REDEEM_CONSTANTS = {
  landingPage: 'https://redeem.gov.sg',
  supportEmail: 'feedback@redeem.gov.sg',
  termsOfUse: 'https://redeem.gov.sg/terms-of-use.html',
  privacy: 'https://redeem.gov.sg/privacy.html',
  reportVulnerability: 'https://www.tech.gov.sg/report_vulnerability',
  campaignListingUrl: LISTING_URL,
}

export const EXTERNAL_CONSTANTS = {
  incorrectAddressHelp: 'https://eservices.ica.gov.sg/esvclandingpage/ecoa',
  // Other constants
  icaEServiceLink: 'https://eservices.ica.gov.sg/esvclandingpage/ecoa',
  singpassSupportEmail: 'support@singpass.gov.sg',
}

export const RESEND_OTP_INTERVAL_IN_SECONDS = 60
export const RATE_LIMIT_INTERVAL_IN_SECONDS = 60
export const OTP_EXPIRY_IN_SECONDS = 10

export const MAX_NETWORK_RETRIES = 3

export const launchDarklySdkClientId =
  process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT ?? ''
export const isLaunchDarklySdkClientAvailable =
  !_.isEmpty(launchDarklySdkClientId) && launchDarklySdkClientId !== 'null'
