import React from 'react'
import { Box, Text } from '@chakra-ui/react'

type DetailsInfoBoxProps = {
  id: string
  titleText: string
  content: React.ReactNode
}

const DetailsInfoBox = ({ id, titleText, content }: DetailsInfoBoxProps) => {
  return (
    <Box
      id={id}
      border="1px solid"
      borderColor="primary.400"
      borderRadius="4px"
      boxSizing="border-box"
      overflow="hidden"
    >
      <Box padding="8px 18px" bg="primary.200">
        <Text textStyle="subhead2" color="primary.700">
          {titleText}
        </Text>
      </Box>
      {content}
    </Box>
  )
}

export default DetailsInfoBox
