import { Trans } from 'react-i18next'
import { Link, Spacer } from '@chakra-ui/react'

import { REDEEM_CONSTANTS } from 'constants/config'
import { formatAreaCodeContact } from 'utils/helpers'
import { ActionLink } from 'components/ActionLink'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'

import { ErrorProps } from './types'

interface SignupLimitReachedProps extends ErrorProps {
  organiserContactNumber?: string | null
}

// TODO: Revisit this after T2 launch

export const SignupLimitReachedError = ({
  t,
  organiserContactNumber,
}: SignupLimitReachedProps) => (
  <>
    <ErrorHeading
      title={t('signupLimitReached.title')}
      textContent={
        organiserContactNumber && (
          <Trans
            t={t}
            i18nKey={'signupLimitReached.info'}
            values={{
              paContact: formatAreaCodeContact(organiserContactNumber),
            }}
          >
            Due to overwhelming response, this CDC Voucher Trial scheme is
            closed for sign up. For assistance, please call PA at
            <Link href={`tel:+${organiserContactNumber}`} variant="subhead">
              {formatAreaCodeContact(organiserContactNumber)}
            </Link>
            .
          </Trans>
        )
      }
    />
    {/* Additional spacing (24px) for actionable links*/}
    <Spacer />
    <ActionLink href={REDEEM_CONSTANTS.landingPage}>
      {t('buttons.goMainPage')}
    </ActionLink>
  </>
)
