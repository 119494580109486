export interface MyInfoParams {
  code?: string
  state?: string
  error?: string
  error_description?: string
}

export interface Person {
  name: string
  nric?: string
  block: string
  postalCode: string
  unit: string
  floor: string
  street: string
  invalidAddress?: string
}

export interface PersonDto extends Person {
  isExisting: boolean
  personJwt: string
}

// TODO: Swap out for state machine
export enum FormPhase {
  NOT_STARTED = 0,
  SINGPASS_AUTHENTICATED = 1,
  ENTER_NUMBER = 2,
  ENTER_OTP = 3,
  SUCCESS = 4,
}

export interface VoucherSet {
  value: number
  quantity: number
}

export enum CampaignEligibility {
  signup_whitelist_nric = 'signup_whitelist_nric',
  signup_conditions_address = 'signup_conditions_address',
  signup_conditions_hdb = 'signup_conditions_hdb',
  no_signup = 'no_signup',
}

export interface Campaign {
  advisoryUrl: string | null
  colour: string
  defaultVouchers: VoucherSet[]
  description: string
  eligibility: CampaignEligibility
  id: string
  isDeleted: boolean
  logoUrl: string | null
  merchantListUrl: string | null
  name: string
  organiserContactNumber: string | null
  organiserEmail: string
  organiserFeedbackUrl: string | null
  organiserLocation: string | null
  organiserName: string
  singpassEserviceId: string
  tncUrl: string | null
  validityEnd?: string | null
  validityStart?: string | null
}

export interface CampaignDto extends Campaign {
  voucherTotalAmount: number
  isTrial: boolean
}

export type ListCampaignEntry = Pick<
  CampaignDto,
  | 'id'
  | 'name'
  | 'description'
  | 'organiserName'
  | 'defaultVouchers'
  | 'validityEnd'
  | 'validityStart'
  | 'logoUrl'
>

export type ListCampaignEntryDto = ListCampaignEntry & {
  voucherTotalAmount: number
}

export interface SubmitResponse {
  voucherUrl: string
  isExisting: boolean
}

export interface Voucher {
  voucher_value: number
}

export interface PersonResponse {
  personJwt: string
  contactNumber: string | null
  isExisting: boolean
}
