import camelcaseKeys from 'camelcase-keys'
import jwtDecode from 'jwt-decode'

import { Person } from 'constants/types'

export default function decodePerson(personJwt: string): Person {
  try {
    const person = jwtDecode(personJwt) as Record<string, unknown>
    return camelcaseKeys(person) as Person
  } catch {
    throw new Error('Failed to decode person data from Redeem API')
  }
}
