import { Center, Image } from '@chakra-ui/react'

import RedeemLogoPng from 'images/redeemsg-full.png'
import RedeemLogoSvg from 'images/redeemsg-full.svg'

export const RedeemBanner = () => (
  <Center bgColor="primary.100" width="100%" height="48px">
    <Image src={RedeemLogoSvg} fallbackSrc={RedeemLogoPng} maxH="20px" />
  </Center>
)
