import { FC } from 'react'
import { TFunction } from 'react-i18next'
import { Text, VStack } from '@chakra-ui/react'

import { CampaignEligibility } from 'constants/types'

interface CampaignContentSectionProps {
  t: TFunction<'login', undefined>
  voucherTotalAmount: number
  eligibilityType: CampaignEligibility
}

enum TranslationType {
  ADDRESS_SPECIFIC = 'addressSpecific',
  GENERIC_SPECIFIC = 'genericSpecific',
  NEA_SPECIFIC = 'neaSpecific',
}

function getTranslationType(eligibilityType: CampaignEligibility) {
  switch (eligibilityType) {
    case CampaignEligibility.signup_conditions_address:
      return TranslationType.ADDRESS_SPECIFIC
    // To be deprecated in the future
    case CampaignEligibility.signup_conditions_hdb:
      return TranslationType.NEA_SPECIFIC
    case CampaignEligibility.signup_whitelist_nric:
      return TranslationType.GENERIC_SPECIFIC
    default:
      return TranslationType.GENERIC_SPECIFIC
  }
}

export const CampaignContentSection: FC<CampaignContentSectionProps> = ({
  t,
  voucherTotalAmount,
  eligibilityType,
}) => {
  const specificity = getTranslationType(eligibilityType)

  return (
    <VStack align="stretch" spacing="8px" textStyle="body1" color="neutral.900">
      <Text textStyle="subhead1">
        {t(`${specificity}.title`, {
          voucherTotalAmount,
        })}
      </Text>
      <Text>
        {t(`${specificity}.householdInstructions`, { voucherTotalAmount })}
      </Text>
    </VStack>
  )
}
