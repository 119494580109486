export type ThemeColorScheme =
  | 'primary'
  | 'secondary'
  | 'neutral'
  | 'standard'
  | 'danger'

export const colors: { [key in ThemeColorScheme]: Record<string, string> } = {
  primary: {
    100: '#F6F7FA',
    200: '#DFE1F2',
    300: '#BCBFE3',
    400: '#868AC3',
    500: '#505798',
    600: '#3B4183',
    700: '#2F3476',
    800: '#222757',
    900: '#1B1E44',
  },
  // TODO: Design pallet is off for this part here
  secondary: {
    100: '#F6F7FC',
    500: '#445072',
  },
  neutral: {
    100: '#FBFCFD',
    300: '#E1E2E4',
    400: '#C9CCCF',
    500: '#ABADB2',
    600: '#69707A',
    700: '#636467',
    800: '#3D4147',
    900: '#26292C',
  },
  standard: {
    white: '#FFFFFF',
    black: '#000000',
  },
  danger: {
    500: '#C05050',
  },
}
