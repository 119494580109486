import { FormPhase } from 'constants/types'

interface CheckValidFormPhaseParams {
  expectedFormPhase: FormPhase
  currentFormPhase: FormPhase
  personJwt: string | undefined
}

// Allow authenticated access when the current stage is equal or after the expected stage
// TODO: Reconsider having a state machine with proper state validation of auth data
export function checkValidFormPhase({
  expectedFormPhase,
  currentFormPhase,
  personJwt,
}: CheckValidFormPhaseParams) {
  return currentFormPhase >= expectedFormPhase && !!personJwt
}
