import { useErrorHandler } from 'react-error-boundary'
import { useMutation } from 'react-query'

import { SubmitResponse } from 'constants/types'
import {
  ApiErrors,
  isNetworkError,
  isSignupError,
  SignupException,
} from 'errors'
import { voucherSignup } from 'services/RedeemApi'

export function useSubmitVoucherClaim() {
  const handleError = useErrorHandler()
  const { mutateAsync, isLoading } = useMutation<
    SubmitResponse,
    ApiErrors,
    {
      lang: string
      personJwt: string
      contactNumberJwt: string
      campaignId: string
    }
  >(
    ({ lang, personJwt, contactNumberJwt, campaignId }) =>
      voucherSignup(lang, personJwt, contactNumberJwt, campaignId),
    {
      onError: (err: unknown, variables) => {
        if (isNetworkError(err)) {
          handleError(
            new SignupException({
              type: 'network_error',
              campaignId: variables.campaignId,
            }),
          )
        }
      },
      // Propagate SignupExceptions to error boundary
      useErrorBoundary: (err) => isSignupError(err),
    },
  )

  return {
    mutateAsync,
    isLoading,
  }
}
