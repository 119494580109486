import React, { useState } from 'react'
import { Link, Text, VStack } from '@chakra-ui/react'

const BULLET_POINT_SHOW_LIMIT = 2

type BulletPointListProps = {
  points: string[]
}

const BulletPointList = ({ points }: BulletPointListProps) => {
  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  return (
    <VStack align="start" spacing={2}>
      <VStack align="start" spacing={1}>
        {points
          .slice(0, showAll ? points.length : BULLET_POINT_SHOW_LIMIT)
          .map((point, index) => (
            <Text variant="bodyLight" key={index}>
              • {point}
            </Text>
          ))}
      </VStack>
      {points.length > BULLET_POINT_SHOW_LIMIT && (
        <Link
          variant="subhead"
          onClick={toggleShowAll}
          textDecoration="underline"
        >
          {showAll ? 'View Less' : 'View More'}
        </Link>
      )}
    </VStack>
  )
}

export default BulletPointList
