import { ComponentStyleConfig } from '@chakra-ui/react'
import { SystemStyleInterpolation } from '@chakra-ui/theme-tools'

import { textStyles } from '../textStyles'

export type LinkVariants =
  | 'footerDesktop'
  | 'action'
  | 'subhead'
  | 'body'
  | 'stillNotReceivingSms'

export const Link: Omit<ComponentStyleConfig, 'variants'> & {
  variants: Record<LinkVariants, SystemStyleInterpolation>
} = {
  baseStyle: {
    color: 'primary.500',
  },
  variants: {
    footerDesktop: {
      ...textStyles.footer,
      color: 'neutral.700',
    },
    action: {
      width: 'fit-content',
      height: 'fit-content',
      ...textStyles.subhead1,
    },
    subhead: {
      ...textStyles.subhead1,
    },
    body: {
      ...textStyles.caption2,
    },
    // Special styling
    stillNotReceivingSms: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}
