import { FC } from 'react'
import { Box, Image, Stack } from '@chakra-ui/react'

import redeemLogoDark from 'images/redeemsg-dark.png'
import redeemLogo from 'images/redeemsg-full.png'
import { LanguageSelector } from 'components/LanguageSelector'

interface HeaderProps {
  logoImageSrc?: string | null
  variant?: 'dark'
  showLogo?: boolean
}

export const HeaderBar: FC<HeaderProps> = ({
  logoImageSrc,
  variant,
  showLogo = true,
}) => {
  return (
    <Stack align="center" justify="space-between" direction="row-reverse">
      <Box position="relative">
        <LanguageSelector variant={variant} />
      </Box>
      <Image
        display={showLogo ? 'block' : 'none'}
        maxHeight="32px"
        maxWidth={{ base: '120px', lg: '170px' }}
        src={logoImageSrc ?? undefined}
        fallbackSrc={variant === 'dark' ? redeemLogoDark : redeemLogo}
        alt="Organisation Logo"
      />
    </Stack>
  )
}
