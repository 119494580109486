export interface ClaimedVoucherDataParams {
  name: string | null
  block: string
  postalCode: string
  unit: string
  floor: string
  street: string
  nric?: string
  previousClaimantName: string | null
}

export type MyInfoDataParams = Record<string, unknown>

// Note: Exists as enum for use as an object in error transformer
export enum SignupErrorCode {
  AUTH_INVALID_ERROR = 'auth_jwt_invalid',
  CAMPAIGN_NOT_STARTED = 'campaign_not_started',
  CAMPAIGN_ENDED = 'campaign_ended',
  CAMPAIGN_NOT_AVAILABLE_FOR_SIGNUP = 'campaign_not_available_for_signup',
  CLAIMED_VOUCHER_FOR_ADDRESS = 'claimed_voucher_for_address',
  CLAIM_INELIGIBLE = 'claim_ineligible',
  MYINFO_INVALID_RESPONSE = 'myinfo_invalid_response',
  MYINFO_TIMEOUT_ERROR = 'myinfo_timeout_error',
  MYINFO_RECORDS_NOT_FOUND = 'myinfo_records_not_found',
  CAMPAIGN_HAS_NO_SINGPASS_E_SERVICE_ID = 'campaign_has_no_singpass_e_service_id',
  CAMPAIGN_HAS_NO_DEFAULT_VOUCHERS = 'campaign_has_no_default_vouchers',
}

type DerivedError =
  | 'campaign_not_found'
  | 'non_default_campaign'
  | 'unauthorised_route_error'
  | 'myinfo_redirect_error'
  | 'myinfo_access_denied'
  | 'boundary_error'

type StaticError =
  | 'service_unavailable'
  | 'signup_limit_reached'
  | 'singpass_unavailable'
  | 'network_error'

/*
Converts SignupErrorCodes enum, with claim_ineligible excluded, into union types
Note: Claim ineligible excluded for now until we have a fallback claim_ineligible
screen to catch ineligible errors with unimplemented ineligibility categories
 */
export type ServerError = `${Exclude<
  SignupErrorCode,
  SignupErrorCode.CLAIM_INELIGIBLE
>}`

export type ClaimNotEligibleError =
  | 'address_type_not_allowed'
  | 'residential_status_not_citizen'
  | 'residential_status_not_citizen_or_pr'
  | 'hdbtype_not_valid'
  | 'myinfo_attribute_not_verified'
  | 'non_residential_address'
  | 'myinfo_attribute_not_available'
  | 'not_in_whitelist'
  | 'buckets_evaluation_ineligible_claim'

export type SignupError =
  | DerivedError
  | StaticError
  | ServerError
  | ClaimNotEligibleError

export type MyinfoReceivedButNotEligibleError =
  | Exclude<ClaimNotEligibleError, `myinfo_${string}`>
  | 'claimed_voucher_for_address'

export type BucketsFact =
  | 'CITIZENSHIP'
  | 'HOUSING_TYPE'
  | 'RESIDENTIAL_ADDRESS'
  | 'NAME'
  | 'UINFIN'

export type BucketsData = {
  expected: string[]
  displayAttribute: string
  attribute: BucketsFact | undefined
  valid: boolean
}
