import { ComponentStyleConfig } from '@chakra-ui/react'

export const Menu: ComponentStyleConfig = {
  parts: ['button', 'label', 'icon', 'list', 'item'],

  baseStyle: {
    button: {
      borderColor: 'primary.500',
      color: 'primary.500',
      borderWidth: '1px',
      width: '139px',
      padding: '8px 12px',
    },
    label: {
      textStyle: 'subhead1',
      textAlign: 'start',
      color: 'primary.500',
    },
    icon: {
      height: '15px',
      width: '15px',
    },
    list: {
      minWidth: '100%',
      paddingTop: 0.5,
      paddingBottom: 0.5,
      borderRadius: 2,
      borderWidth: '0.5px',
      borderColor: 'neutral.400',
      filter: 'drop-shadow(0px 0px 20px rgba(97, 108, 137, 0.3))',
      zIndex: 10,
    },
    item: {
      height: '40px',
      paddingLeft: '40px',
      _hover: {
        background: 'primary.100',
      },
      _focus: {
        background: 'primary.200',
      },

      textStyle: 'body1',
      textAlign: 'start',
    },
  },
  variants: {
    dark: {
      button: {
        borderColor: 'primary.500',
        color: 'standard.white',
        _active: {
          backgroundColor: 'transparent',
        },
      },
      label: {
        color: 'standard.white',
      },
    },
  },
}
