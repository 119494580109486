import callback from './callbackMS.json'
import common from './commonMS.json'
import contact from './contactMS.json'
import contactOld from './contactOldMS.json'
import error from './errorMS.json'
import footer from './footerMS.json'
import listing from './listingMS.json'
import loading from './loadingMS.json'
import login from './loginMS.json'
import register from './registerMS.json'
import success from './successMS.json'
import trial from './trialMS.json'
import verify from './verifyMS.json'

export const translationsMS = {
  common,
  callback,
  contact,
  contactOld,
  error,
  footer,
  listing,
  loading,
  login,
  register,
  success,
  trial,
  verify,
}
