import { Trans } from 'react-i18next'
import { Link } from '@chakra-ui/react'

import { NeedHelpTextOnlyInfoBox } from 'components/HelpInfo/NeedHelpTextOnlyInfoBox'

import TemplateError from './TemplateError'
import { ErrorProps } from './types'

const NotAvailableOrApplicableOrVerifiedError = ({ t }: ErrorProps) => {
  return (
    <TemplateError
      titleText={t('invalidMyInfoField.title')}
      helpInfoBox={
        <NeedHelpTextOnlyInfoBox>
          <Trans t={t} i18nKey={'invalidMyInfoField.helpInfo'}>
            {'Please contact Singpass at '}
            <Link
              href="mailto:support@singpass.gov.sg"
              textStyle="body2"
              color="primary.500"
              textDecoration="underline"
            >
              support@singpass.gov.sg
            </Link>
            {
              ' for assistance. Do attach a screenshot of this screen in your e-mail.'
            }
          </Trans>
        </NeedHelpTextOnlyInfoBox>
      }
    />
  )
}

export default NotAvailableOrApplicableOrVerifiedError
