import HelpInfoBox from './HelpInfoBox'

type ForMoreHelpInfoBoxProps = {
  children: React.ReactNode
}

export const ForMoreHelpInfoBox = ({ children }: ForMoreHelpInfoBoxProps) => {
  return (
    <HelpInfoBox
      headeri18nKey="moreHelpHeading"
      headerBackgroundColor="primary.100"
    >
      {children}
    </HelpInfoBox>
  )
}
