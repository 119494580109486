import { FC } from 'react'
import { Center, Image } from '@chakra-ui/react'

import UserPhoneQrImagePng from 'images/user-phone-qr.png'
import UserPhoneQrImageSvg from 'images/user-phone-qr.svg'

export const LoginImageMobile: FC = () => {
  return (
    <Center display={{ base: 'block', lg: 'none' }}>
      <Image
        src={UserPhoneQrImageSvg}
        fallbackSrc={UserPhoneQrImagePng}
        alt="Left column image"
      />
    </Center>
  )
}
