import { createContext, FC, useCallback, useContext, useState } from 'react'

import { FormPhase, PersonDto } from 'constants/types'
import { checkValidFormPhase } from 'utils/auth'

interface AuthContextProps {
  // Getters
  phone: string
  voucherUrl: string
  person: PersonDto | undefined

  // Setters
  setFullPerson: (person: PersonDto) => void
  setFormPhase: (currentFormPhase: FormPhase) => void
  setPhoneState: (phone: string) => void
  setVoucherUrl: (voucherUrl: string) => void
  verifyAllowedRouteState: (expectedFormPhase: FormPhase) => boolean
}

const authContext = createContext<AuthContextProps | undefined>(undefined)

export const useAuth = (): AuthContextProps => {
  const auth = useContext(authContext)
  if (!auth) throw new Error('useAuth must be used within an AuthProvider')
  return auth
}

export const AuthProvider: FC = ({ children }) => {
  const [formState, setFormState] = useState(FormPhase.NOT_STARTED)
  const [voucher, setVoucher] = useState('')
  const [person, setPerson] = useState<PersonDto | undefined>()
  const [phone, setPhone] = useState('')

  const setFormPhase = useCallback((currentFormPhase: FormPhase) => {
    setFormState(currentFormPhase)
  }, [])

  const setPhoneState = useCallback((phone: string) => {
    setPhone(phone)
  }, [])

  const setVoucherUrl = useCallback((voucherUrl: string) => {
    setVoucher(voucherUrl)
  }, [])

  const setFullPerson = useCallback((person: PersonDto) => {
    setPerson(person)
  }, [])

  const verifyAllowedRouteState = useCallback(
    (expectedFormPhase: FormPhase) => {
      return checkValidFormPhase({
        expectedFormPhase,
        currentFormPhase: formState,
        personJwt: person?.personJwt,
      })
    },
    [person, formState],
  )

  const auth = {
    person,
    phone,
    voucherUrl: voucher,
    setFullPerson,
    setFormPhase,
    setPhoneState,
    setVoucherUrl,
    verifyAllowedRouteState,
  }

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
