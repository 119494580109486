import { FC, ReactNode } from 'react'
import { Box, Center, HStack, Image, Spacer, VStack } from '@chakra-ui/react'

import UserPhoneQrGraphicPng from 'images/user-phone-qr.png'
import UserPhoneQrGraphicSvg from 'images/user-phone-qr.svg'

interface DisplayContainerProps {
  children: ReactNode
  heroImageSrc?: string
  heroImageSrcFallback?: string
  footerContent?: ReactNode
  mobileBannerContent?: ReactNode
}

export const DisplayContainer: FC<DisplayContainerProps> = ({
  children,
  heroImageSrc,
  heroImageSrcFallback,
  footerContent,
  mobileBannerContent,
}) => {
  return (
    <HStack align="stretch" flex={1} minH="calc(100vh - 30px)" spacing="0px">
      <Center
        maxW="40%"
        bg="primary.100"
        flex={1}
        display={{ base: 'none', lg: 'flex' }}
      >
        <Image
          px="15%"
          src={heroImageSrc || UserPhoneQrGraphicSvg}
          alt="Hero image"
          fallbackSrc={heroImageSrcFallback || UserPhoneQrGraphicPng}
        />
      </Center>
      <VStack flex={1} spacing="0px">
        <Box width="100%" display={{ base: 'block', lg: 'none' }}>
          {mobileBannerContent}
        </Box>
        <VStack
          padding={{ base: '32px 24px', lg: '6% 0px 50px' }}
          w={{ base: '100%', md: '514px' }}
          align="stretch"
          spacing={{ base: '24px', lg: '40px' }}
          justify="center"
        >
          {children}
        </VStack>
        <Spacer />
        {footerContent && (
          <Box
            padding={{ base: '0px 24px 24px', lg: '0px 0px 40px' }}
            w={{ base: '100%', md: '514px' }}
          >
            {footerContent}
          </Box>
        )}
      </VStack>
    </HStack>
  )
}
