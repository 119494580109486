import { FC } from 'react'
import { SimpleGrid, useBreakpointValue } from '@chakra-ui/react'

import { ListCampaignEntryDto } from 'constants/types'

import { CampaignCard } from 'pages/CampaignListing/components/CampaignCard'

interface CampaignCardsContainerProps {
  campaigns: ListCampaignEntryDto[]
}

export const CampaignCardsContainer: FC<CampaignCardsContainerProps> = ({
  campaigns,
}) => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })

  return (
    <SimpleGrid
      marginTop={{ base: '24px', md: '-20px', lg: '-46px' }}
      columns={{ base: 1, md: 2 }}
      spacing="16px"
      px="16px"
      w="100%"
      maxW="1144px"
    >
      {campaigns.map((campaign, i) => {
        const isNew = i === 0

        return (
          <CampaignCard
            size={isMobileWidth ? 'sm' : 'lg'}
            key={campaign.id}
            id={campaign.id}
            title={campaign.name}
            voucherTotalAmount={campaign.voucherTotalAmount}
            validityStart={campaign.validityStart}
            validityEnd={campaign.validityEnd}
            logoUrl={campaign.logoUrl}
            isNew={isNew}
          />
        )
      })}
    </SimpleGrid>
  )
}
