import { useTranslation } from 'react-i18next'

import { CampaignDto } from 'constants/types'
import { BucketsData, ClaimedVoucherDataParams, SignupError } from 'errors'
import useScrollToTopOnRender from 'hooks/useScrollToTopOnRender'

import { DefaultError } from 'pages/ErrorBoundary/templates/DefaultError'

import { BlacklistedError } from './templates/Blacklisted'
import { BucketsError } from './templates/BucketsError'
import { CampaignEndedError } from './templates/CampaignEnded'
import { CampaignNotFoundError } from './templates/CampaignNotFound'
import { CampaignNotReadyError } from './templates/CampaignNotReady'
import { InvalidAddressError } from './templates/InvalidAddress'
import { InvalidAlreadyClaimedError } from './templates/InvalidAlreadyClaimed'
import { InvalidCitizenshipError } from './templates/InvalidCitizenship'
import { InvalidHDBTypeError } from './templates/InvalidHDBType'
import { MyinfoRecordsNotFoundError } from './templates/MyinfoRecordsNotFound'
import { NetworkError } from './templates/NetworkError'
import { NotAuthorisedError } from './templates/NotAuthorised'
import NotAvailableOrApplicableOrVerifiedError from './templates/NotAvailableOrApplicableOrVerifiedError'
import { NotEligibleForCampaignError } from './templates/NotEligibleForCampaign'
import { ServiceUnavailableError } from './templates/ServiceUnavailable'
import { SessionExpiredError } from './templates/SessionExpired'
import { SignupLimitReachedError } from './templates/SignupLimitReached'
import { SingpassAccessDeniedError } from './templates/SingpassAccessDenied'
import { SingpassUnavailableError } from './templates/SingpassUnavailable'

interface ErrorBoundaryProps {
  errorType: SignupError
  handleRedirectLogin: () => void
  claimedVoucherData?: ClaimedVoucherDataParams
  campaign?: CampaignDto
  bucketsData?: BucketsData
}

export const ErrorBoundary = ({
  errorType,
  handleRedirectLogin,
  campaign,
  claimedVoucherData,
  bucketsData,
}: ErrorBoundaryProps) => {
  useScrollToTopOnRender()

  const { t } = useTranslation('error')
  switch (errorType) {
    case 'myinfo_access_denied':
      return (
        <SingpassAccessDeniedError
          t={t}
          handleRedirectLogin={handleRedirectLogin}
        />
      )

    case 'campaign_not_started':
    case 'non_default_campaign':
    case 'campaign_not_available_for_signup':
    case 'campaign_has_no_singpass_e_service_id':
    case 'campaign_has_no_default_vouchers':
      return <CampaignNotReadyError t={t} />

    // NOTE: Currently only address structure is checked for validity
    case 'myinfo_invalid_response':
    case 'non_residential_address':
      return <BlacklistedError t={t} campaign={campaign} />

    case 'myinfo_timeout_error':
    case 'singpass_unavailable':
      return (
        <SingpassUnavailableError
          t={t}
          handleRedirectLogin={handleRedirectLogin}
        />
      )

    case 'myinfo_redirect_error':
    case 'unauthorised_route_error':
      return (
        <NotAuthorisedError t={t} handleRedirectLogin={handleRedirectLogin} />
      )

    case 'campaign_not_found':
      return <CampaignNotFoundError t={t} />

    case 'campaign_ended':
      return <CampaignEndedError t={t} campaign={campaign} />

    // Not applicable error to be here in the future as now it is tied directly to the "type of search" (i.e not citizen rather than not applicable)
    case 'myinfo_attribute_not_verified':
    case 'myinfo_attribute_not_available':
      return <NotAvailableOrApplicableOrVerifiedError t={t} />

    case 'myinfo_records_not_found':
      return <MyinfoRecordsNotFoundError t={t} />

    case 'service_unavailable':
      return <ServiceUnavailableError t={t} />

    case 'auth_jwt_invalid':
      return <SessionExpiredError t={t} />

    case 'not_in_whitelist':
      return <NotEligibleForCampaignError t={t} campaign={campaign} />

    case 'residential_status_not_citizen':
      return !campaign?.name ? null : (
        <InvalidCitizenshipError
          t={t}
          campaignName={campaign?.name}
          eligibleCriteria={['Singaporean Citizen']}
        />
      )

    case 'residential_status_not_citizen_or_pr':
      return !campaign?.name ? null : (
        <InvalidCitizenshipError
          t={t}
          campaignName={campaign?.name}
          eligibleCriteria={['Singaporean Citizen', 'Permanent Resident (PR)']}
        />
      )

    case 'hdbtype_not_valid':
      return !campaign?.name ? null : (
        <InvalidHDBTypeError
          t={t}
          campaignName={campaign?.name}
          eligibilityCriteria={[
            '1-room Flat (HDB)',
            '2-room Flat (HDB)',
            '3-room Flat (HDB)',
            '4-room Flat (HDB)',
            '5-room Flat (HDB)',
            'Executive Flat (HDB)',
            'Studio Apartment (HDB)',
          ]}
        />
      )

    case 'address_type_not_allowed':
      return !campaign?.name ? null : (
        <InvalidAddressError t={t} campaignName={campaign?.name} />
      )

    case 'signup_limit_reached':
      // TODO: Remove fallbacks in T2
      return (
        <SignupLimitReachedError
          t={t}
          organiserContactNumber={campaign?.organiserContactNumber}
        />
      )

    case 'claimed_voucher_for_address':
      return !claimedVoucherData || !campaign ? null : (
        <InvalidAlreadyClaimedError
          t={t}
          claimedVoucherData={claimedVoucherData}
          campaign={campaign}
          handleRedirectLogin={handleRedirectLogin}
        />
      )

    case 'buckets_evaluation_ineligible_claim':
      return !campaign?.name ? null : (
        <BucketsError
          t={t}
          bucketsData={bucketsData}
          campaignName={campaign?.name}
        />
      )

    case 'network_error':
      return <NetworkError t={t} handleRedirectLogin={handleRedirectLogin} />

    case 'boundary_error':
      return <DefaultError />

    default:
      return <DefaultError />
  }
}
