import callback from './callbackZH.json'
import common from './commonZH.json'
import contactOld from './contactOldZH.json'
import contact from './contactZH.json'
import error from './errorZH.json'
import footer from './footerZH.json'
import listing from './listingZH.json'
import loading from './loadingZH.json'
import login from './loginZH.json'
import register from './registerZH.json'
import success from './successZH.json'
import trial from './trialZH.json'
import verify from './verifyZH.json'

export const translationsZH = {
  common,
  callback,
  contact,
  contactOld,
  error,
  footer,
  listing,
  loading,
  login,
  register,
  success,
  trial,
  verify,
}
