import { VStack } from '@chakra-ui/react'

import {
  CampaignListingDetails,
  RedeemContactDetails,
} from 'pages/ErrorBoundary/components/AdditionalDetails'
import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

export const CampaignNotFoundError = ({ t }: ErrorProps) => (
  <ErrorHeading
    title={t('pageNotFound.title')}
    componentContent={
      <VStack align="flex-start" spacing="16px">
        <CampaignListingDetails t={t} />
        <RedeemContactDetails t={t} />
      </VStack>
    }
  />
)
