import { As, Flex, Icon, Text } from '@chakra-ui/react'

interface RestrictedGovtMastheadItemProps {
  icon: As
  header: string
  children: React.ReactNode
}

export const RestrictedGovtMastheadItem = ({
  icon,
  header,
  children,
}: RestrictedGovtMastheadItemProps): JSX.Element => {
  return (
    <Flex flex={1} maxWidth="32rem">
      <Icon
        as={icon}
        marginRight={{ base: '0.5rem', lg: '0.75rem' }}
        fontSize={{ base: '1rem', lg: '1.5rem' }}
        aria-hidden
      />
      <Flex flexDirection="column">
        <Text
          textStyle={{ base: 'caption-1', lg: 'subhead-1' }}
          marginBottom="0.75rem"
        >
          {header}
        </Text>
        {children}
      </Flex>
    </Flex>
  )
}
