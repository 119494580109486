import { useEffect } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

type DDAddCustomActionParam = {
  actionName: string
  context: object | undefined
}
export function ddAddCustomAction({
  actionName,
  context,
}: DDAddCustomActionParam) {
  datadogRum.addAction(actionName, context)
}

export function customMonitorClick(targetName: string) {
  ddAddCustomAction({
    actionName: targetName,
    context: {
      target: {
        name: targetName,
      },
    },
  })
}

export function useMonitorLanguage(language: string) {
  useEffect(() => {
    // Override previously set language as it can be changed by the user
    datadogRum.removeRumGlobalContext('language')
    datadogRum.addRumGlobalContext('language', language)
  }, [language])
}

export function getCustomSessionId() {
  // Retrieves datadog session id from logs context
  const logContext = datadogLogs.getInternalContext()
  if (logContext) {
    return logContext.session_id
  }
  return null
}
