import { FieldError } from 'react-hook-form'
import { UseFormRegister } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  Text,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react'

import { SG_PHONE_REGEX } from 'constants/regex'
import { DDAction } from 'utils/monitoring'
import { Heading } from 'components/Heading'

interface PhoneFormPageProps {
  handlePhoneFormSubmit: () => void
  register: UseFormRegister<{ phone: string }>
  isSubmitting: boolean
  isButtonDisabled: boolean
  phoneFieldError?: FieldError
}

// TODO: Evaluate if can merge into a single form page (phone + otp)
export const PhoneFormPage = ({
  handlePhoneFormSubmit,
  register,
  isSubmitting,
  isButtonDisabled,
  phoneFieldError,
}: PhoneFormPageProps) => {
  const { t } = useTranslation('register')

  const styles = useMultiStyleConfig('FormInput', { variant: 'phone' })

  return (
    <form onSubmit={handlePhoneFormSubmit} style={{ width: '100%' }}>
      <VStack align="stretch" spacing={{ base: '24px' }}>
        <Heading title={t('verifyNumber')} content={t('verifyNumberInfo')} />
        <FormControl isInvalid={!!phoneFieldError}>
          <FormLabel textStyle="subhead1">{t('mobileNumber')}</FormLabel>
          <HStack>
            <InputGroup>
              <Input
                sx={styles.input}
                inputMode="tel"
                autoFocus
                maxLength={8}
                data-dd-privacy="mask"
                {...register('phone', {
                  required: {
                    value: true,
                    message: t('malformedNumber'),
                  },
                  pattern: {
                    value: SG_PHONE_REGEX,
                    message: t('malformedNumber'),
                  },
                })}
              />
            </InputGroup>
          </HStack>
          <FormErrorMessage>
            <HStack flex={1} alignItems="baseline" sx={styles.error}>
              <Icon as={MdError} />
              <Text id="phone-input-error">
                {phoneFieldError && phoneFieldError.message}
              </Text>
            </HStack>
          </FormErrorMessage>
        </FormControl>
        <Button
          isLoading={isSubmitting}
          isDisabled={isButtonDisabled}
          type="submit"
          data-dd-action-name={DDAction.SUBMIT_PHONE_BUTTON}
        >
          {t('sendOtp')}
        </Button>
      </VStack>
    </form>
  )
}
