import callback from './callbackEN.json'
import common from './commonEN.json'
import contact from './contactEN.json'
import contactOld from './contactOldEN.json'
import error from './errorEN.json'
import footer from './footerEN.json'
import listing from './listingEN.json'
import loading from './loadingEN.json'
import login from './loginEN.json'
import register from './registerEN.json'
import success from './successEN.json'
import trial from './trialEN.json'
import verify from './verifyEN.json'

export const translationsEN = {
  common,
  callback,
  contact,
  contactOld,
  error,
  footer,
  listing,
  loading,
  login,
  register,
  success,
  trial,
  verify,
}
