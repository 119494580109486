import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { SignupException } from 'errors'

type StaticErrorTypes =
  | 'trial_closed'
  | 'singpass_unavailable'
  | 'service_unavailable'

export const StaticErrorPage: FC = () => {
  const { errorType } = useParams<{ errorType: StaticErrorTypes }>()

  switch (errorType) {
    case 'trial_closed':
      throw new SignupException({
        type: 'signup_limit_reached',
      })

    case 'singpass_unavailable':
      throw new SignupException({
        type: 'singpass_unavailable',
      })

    case 'service_unavailable':
      throw new SignupException({
        type: 'service_unavailable',
      })

    default:
      throw new SignupException({ type: errorType })
  }
}
