import { useTranslation } from 'react-i18next'
import { Button, Link, VStack } from '@chakra-ui/react'

import { EXTERNAL_CONSTANTS } from 'constants/config'
import type { Person } from 'constants/types'
import { customMonitorClick } from 'utils/additionalMonitoring'
import { DDAction } from 'utils/monitoring'

import { Heading } from './Heading'
import { YourDetailsPane } from './YourDetailsPane'

type DetailsLayoutProps = {
  person: Person | undefined
  onPrimaryClick: () => void
  title: string
  content?: string
}

const DetailsLayout = ({
  person,
  onPrimaryClick,
  title,
  content,
}: DetailsLayoutProps) => {
  const { t } = useTranslation('callback')

  return (
    <VStack align="stretch" spacing={{ base: '24px' }}>
      <Heading title={title} content={content} />
      <YourDetailsPane {...person} />
      <Button variant="primary" onClick={onPrimaryClick}>
        {t('next')}
      </Button>
      <Link
        variant="subhead"
        textDecoration="underline"
        mt="16px"
        alignSelf="center"
        href={EXTERNAL_CONSTANTS.incorrectAddressHelp}
        isExternal
        onClick={() => customMonitorClick(DDAction.ICA_INCORRECT_ADDRESS)}
      >
        {t('incorrectInfo')}
      </Link>
    </VStack>
  )
}

export default DetailsLayout
