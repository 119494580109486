export function generateNonce(): string {
  const randomString: string = Math.floor(Math.random() * 1000000000).toString()
  sessionStorage.setItem('nonce', randomString)
  return randomString
}

export function retrieveNonce() {
  return sessionStorage.getItem('nonce')
}

export function isNonceMatch(candidate: string): boolean {
  const storedString = retrieveNonce()
  if (storedString === null || candidate === null) {
    return false
  }
  return storedString === candidate
}

export function clearSessionStorage(): void {
  return sessionStorage.clear()
}
