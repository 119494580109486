import React from 'react'
import { Text } from '@chakra-ui/react'

type HelpInfoTextOnlyProps = {
  children: React.ReactNode
}

const HelpInfoTextOnly = ({ children }: HelpInfoTextOnlyProps) => {
  return (
    <Text textStyle="body2" color="neutral.700" id="help-info-text-content">
      {children}
    </Text>
  )
}

export default HelpInfoTextOnly
