import { RedeemContactDetails } from 'pages/ErrorBoundary/components/AdditionalDetails'
import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

export const ServiceUnavailableError = ({ t }: ErrorProps) => (
  <ErrorHeading
    title={t('serviceUnavailable')}
    componentContent={<RedeemContactDetails t={t} />}
  />
)
