import { Banner } from './Banner'
import { Button } from './Button'
import { CampaignCard } from './CampaignCard'
import { FormInput } from './FormInput'
import { Input } from './Input'
import { Link } from './Link'
import { Menu } from './Menu'
import { ModalLink } from './ModalLink'
import { Spinner } from './Spinner'
import { Text } from './Text'

export const components = {
  Banner,
  Button,
  Link,
  Text,
  Spinner,
  FormInput,
  Input,
  Menu,
  ModalLink,
  CampaignCard,
}
