import { FC, ReactNode } from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { HStack, Icon, Link, LinkProps } from '@chakra-ui/react'

import { TextStyles } from 'theme/textStyles'

type ActionLinkProps = {
  children: ReactNode
  href: LinkProps['href']
  overrideTextStyle?: TextStyles
} & LinkProps

export const ActionLink: FC<ActionLinkProps> = ({
  children,
  href,
  overrideTextStyle,
  ...linkProps
}) => {
  return (
    <Link
      variant="action"
      textStyle={overrideTextStyle}
      href={href}
      isExternal
      {...linkProps}
    >
      <HStack>
        <>{children} &nbsp;</>
        <Icon as={BiRightArrowAlt} />
      </HStack>
    </Link>
  )
}
