import { Link } from '@chakra-ui/react'

import { EXTERNAL_CONSTANTS } from 'constants/config'

const ICALink = () => {
  return (
    <Link
      href={EXTERNAL_CONSTANTS.icaEServiceLink}
      isExternal
      textDecoration="underline"
    >
      ICA's e-services
    </Link>
  )
}

export default ICALink
