import { useTranslation } from 'react-i18next'
import { Text, VStack } from '@chakra-ui/react'

import { formatAddress } from 'utils/helpers'
import { locales } from 'utils/i18n'

import DetailsInfoBox from './DetailsInfoBox'
import { DetailsPaneProps } from './types'

type DetailsInformation = {
  label: string
  value: string | null | undefined
  translationKey: keyof typeof locales['en']['callback']
  isMask: boolean
  mustShow: boolean
}

export const YourDetailsPane = ({
  street,
  block,
  postalCode,
  unit,
  floor,
  name,
  nric,
  invalidAddress,
}: DetailsPaneProps) => {
  const { t } = useTranslation('callback')

  const address = invalidAddress
    ? invalidAddress
    : formatAddress({
        block,
        street,
        postalCode,
        unit,
        floor,
      })

  const detailsInformation: DetailsInformation[] = [
    {
      label: 'Name',
      value: name?.toLowerCase(),
      translationKey: 'name',
      isMask: false,
      mustShow: true,
    },
    {
      label: 'NRIC Number',
      value: nric,
      translationKey: 'nric',
      isMask: true,
      mustShow: false,
    },
    {
      label: 'Registered Address',
      value: address,
      translationKey: 'address',
      isMask: false,
      mustShow: true,
    },
  ]

  return (
    <VStack align="stretch" spacing={{ base: '8px', lg: '16px' }}>
      <DetailsInfoBox
        id="your-details-pane"
        titleText={t('yourDetails')}
        content={
          <VStack padding="16px 18px" spacing="16px" align="start">
            {detailsInformation.map(
              ({ label, value, translationKey, isMask, mustShow }) => {
                // If label !== NRIC, we will always show. If label == nric, then we will check if the value is non falsy before showing the title and body.
                // Thus we use mustShow flag to represent this behaviour. If must show is false, we will check if the value is not falsy first before showing
                // We use isMask as a flag to decide if a field should be mask.
                // This is to avoid using string as comparison
                return (
                  (mustShow || !!value) && (
                    <VStack spacing="2px" key={label} align="start">
                      <Text textStyle="caption1" color="neutral.500">
                        {t(translationKey)}
                      </Text>
                      <Text
                        textStyle="subhead1"
                        color="neutral.800"
                        textTransform="capitalize"
                        {...(isMask ? { 'data-dd-privacy': 'mask' } : {})}
                      >
                        {value}
                      </Text>
                    </VStack>
                  )
                )
              },
            )}
          </VStack>
        }
      />
    </VStack>
  )
}
