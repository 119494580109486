import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'

import { VoucherSet } from 'constants/types'

interface FormatAddressParams {
  postalCode?: string
  block?: string
  unit?: string
  floor?: string
  street?: string
}

export function addDelay(delay: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

export function formatAddress({
  postalCode,
  block,
  unit,
  floor,
  street,
}: FormatAddressParams): string {
  if (
    isEmpty(postalCode) &&
    isEmpty(block) &&
    isEmpty(unit) &&
    isEmpty(floor) &&
    isEmpty(street)
  ) {
    return '-'
  }

  const address = [`${block} ${street}`]
  if (floor && unit) {
    address.push(`#${floor}-${unit}`)
  }

  // MyInfo is known to have returned a unit without a floor.
  // Having this separate formatting for clarity for MOPs and Ops debugging
  if (!floor && unit) {
    address.push(`Unit ${unit}`)
  }

  address.push(`Singapore ${postalCode}`)
  return address.join(', ')
}

export function formatAreaCodeContact(contact: string) {
  return contact.substring(2, 6) + ' ' + contact.substring(6, 10)
}

export function stripUrlScheme(url: string) {
  return url.replace(/^https?:\/\//, '')
}

export function getVoucherTotalAmount(voucherSets: VoucherSet[]) {
  return _.sumBy(
    voucherSets,
    (voucherSet) => voucherSet.value * voucherSet.quantity,
  )
}

export function formatDateString(dateString: string | null | undefined) {
  if (!_.isString(dateString)) {
    return dateString
  }
  return moment(dateString).format('DD MMM YYYY')
}
