import type { ComponentStyleConfig } from '@chakra-ui/react'

import { textStyles } from '../textStyles'

export const Button: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    primary: {
      ...textStyles.subhead1,
      background: 'primary.500',
      width: '100%',
      color: 'standard.white',
      paddingY: '1rem',
      // For dealing with long tamil translations
      whiteSpace: 'normal',
      height: 'fit-content',
      borderRadius: '4px',
      _loading: {
        _hover: {
          background: 'primary.500',
        },
      },
      _hover: {
        _disabled: {
          bg: 'primary.500',
        },
      },
    },
    outline: {
      borderColor: 'primary.500',
      color: 'primary.500',
    },
    link: {
      ...textStyles.subhead1,
      boxShadow: 'none',
      background: 'none',
      color: 'primary.500',
      whiteSpace: 'normal',
      width: 'fit-content',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
}
