import HelpInfoBox from './HelpInfoBox'

type NeedHelpInfoBoxProps = {
  children: React.ReactNode
}

export const NeedHelpInfoBox = ({ children }: NeedHelpInfoBoxProps) => {
  return (
    <HelpInfoBox
      headeri18nKey="needHelpHeading"
      headerBackgroundColor="primary.200"
    >
      {children}
    </HelpInfoBox>
  )
}
