import { BiRightArrowAlt } from 'react-icons/bi'
import { Button } from '@chakra-ui/react'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

interface SingpassUnavailableErrorProps extends ErrorProps {
  handleRedirectLogin: () => void
}

export const SingpassUnavailableError = ({
  t,
  handleRedirectLogin,
}: SingpassUnavailableErrorProps) => (
  <>
    <ErrorHeading title={t('singpassUnavailable')} />
    <Button
      onClick={handleRedirectLogin}
      rightIcon={<BiRightArrowAlt />}
      children={t('buttons.returnLogin')}
    />
  </>
)
