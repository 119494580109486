import { Text } from '@chakra-ui/react'

import { getCustomSessionId } from 'utils/additionalMonitoring'

import { RedeemContactDetailsWithScreenshot } from 'pages/ErrorBoundary/components/AdditionalDetails'
import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'

// TODO: Add translations
export const DefaultError = () => {
  const customSessionId = getCustomSessionId()

  return (
    <ErrorHeading
      title="An unexpected error has occurred"
      componentContent={
        <>
          <RedeemContactDetailsWithScreenshot />
          {!!customSessionId && (
            <Text variant="caption" marginTop="20px">
              Session code: {customSessionId}
            </Text>
          )}
        </>
      }
    />
  )
}
