import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { SignupRouter } from 'app/SignupRouter'

import { isLaunchDarklySdkClientAvailable } from 'constants/config'
import DetectDefaultLanguage from 'components/DetectDefaultLanguage'
import FeatureFlagNotificationBanner from 'components/FeatureFlagNotificationBanner'
import { Footer } from 'components/Footer'
import { RestrictedGovtMasthead } from 'components/RestrictedGovtMasthead'
import ScrollToTop from 'components/ScrollToTop'

import { CampaignListingContainer } from 'pages/CampaignListing/CampaignListingContainer'
import { ErrorBoundaryContainer } from 'pages/ErrorBoundary/ErrorBoundaryContainer'
import { StaticErrorPage } from 'pages/StaticError'

export const AppRouter = (): JSX.Element => {
  return (
    <>
      <RestrictedGovtMasthead />
      {isLaunchDarklySdkClientAvailable && <FeatureFlagNotificationBanner />}
      <Router>
        <ScrollToTop />
        <DetectDefaultLanguage />
        <ErrorBoundary FallbackComponent={ErrorBoundaryContainer}>
          <Switch>
            <Route path="/error/:errorType">
              <StaticErrorPage />
            </Route>
            <Route path="/" exact>
              <CampaignListingContainer />
            </Route>
            <Route path="*">
              <SignupRouter />
              <Footer />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Router>
    </>
  )
}
