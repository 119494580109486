import { BiRightArrowAlt } from 'react-icons/bi'
import { Button } from '@chakra-ui/react'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

interface NetworkErrorProps extends ErrorProps {
  handleRedirectLogin: () => void
}

export const NetworkError = ({ t, handleRedirectLogin }: NetworkErrorProps) => (
  <>
    <ErrorHeading title={t('networkError')} />
    <Button
      onClick={handleRedirectLogin}
      rightIcon={<BiRightArrowAlt />}
      children={t('buttons.returnLogin')}
    />
  </>
)
