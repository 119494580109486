import { createContext, FC, useContext } from 'react'

import { CampaignDto } from 'constants/types'
import { useGetCampaignById } from 'hooks/Campaign'

// TODO: Consider removing context entirely
// react-query's infinite stale time functions like a global context as it is
// using a context like this leads to situations where non-null assertions are required (see SuccessPage.tsx)
// we can handle it within each component instead since loading screens do change across pages
// The alternative is to supply the useCampaignContext hook with the specific loading component desired by caller

interface CampaignContextProps {
  // Getters
  campaign: CampaignDto | undefined
  routeCampaignId: string
}

const CampaignContext = createContext<CampaignContextProps | undefined>(
  undefined,
)

export const useCampaignContext = (): CampaignContextProps => {
  const campaignData = useContext(CampaignContext)
  if (!campaignData)
    throw new Error('useCampaignId must be used within an AuthProvider')
  return campaignData
}

export const CampaignProvider: FC<{ campaignId: string }> = ({
  children,
  campaignId,
}) => {
  const { campaignData } = useGetCampaignById({ campaignId })

  return (
    <CampaignContext.Provider
      value={{
        campaign: campaignData,
        routeCampaignId: campaignId,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}
