import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'constants/translation'
import {
  Campaign,
  ListCampaignEntry,
  PersonResponse,
  SubmitResponse,
} from 'constants/types'

import ApiService from './baseConfig'

// TODO: Swap over to singular endpoint for verify and claim
export function voucherSignup(
  lang: string,
  personJwt: string,
  contactNumberJwt: string,
  campaignId: string,
): Promise<SubmitResponse> {
  // Forcing language to be valid
  const selectedLocale =
    SUPPORTED_LOCALES.find(
      // Get base language from locale
      (locale) => locale.code === lang.substring(0, 2), // en-US -> en
    ) || DEFAULT_LOCALE

  return ApiService.post<SubmitResponse>(
    `/public/signup/submit?lang=${selectedLocale.code}`,
    {
      person_jwt: personJwt,
      contact_number_jwt: contactNumberJwt,
      campaign_id: campaignId,
    },
  ).then((response) => response.data)
}

export function retrievePersonDetails(
  authCode: string,
  campaignId: string,
): Promise<PersonResponse> {
  return ApiService.get<PersonResponse>(
    `/public/signup/retrieve-person?auth_code=${authCode}&campaign_id=${campaignId}`,
  ).then((response) => response.data)
}

export function registerOtp({
  contactNumber,
  campaignId,
  lang,
}: {
  contactNumber: string
  campaignId: string
  lang: string
}): Promise<void> {
  // Forcing language to be valid
  const selectedLocale =
    SUPPORTED_LOCALES.find(
      // Get base language from locale
      (locale) => locale.code === lang.substring(0, 2), // en-US -> en
    ) || DEFAULT_LOCALE

  return ApiService.post<void>(
    `/public/signup/otp/register?lang=${selectedLocale.code}`,
    {
      contact_number: contactNumber,
      campaign_id: campaignId,
    },
  ).then((response) => response.data)
}

export function verifyOtp(contactNumber: string, otp: string): Promise<string> {
  return ApiService.post<string>('/public/signup/otp/verify', {
    contact_number: contactNumber,
    otp,
  }).then((response) => response.data)
}
export function getCampaign(campaignId: string): Promise<Campaign> {
  return ApiService.get<Campaign>(
    `/public/signup/campaigns/${campaignId}`,
  ).then((response) => response.data)
}

export function getListOfPublicCampaigns(): Promise<ListCampaignEntry[]> {
  return ApiService.get<{ data: ListCampaignEntry[] }>(
    '/public/campaigns/?order_by=categories',
  ).then((response) => response.data.data)
}
