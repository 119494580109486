import { Trans } from 'react-i18next'
import { Divider, Text, VStack } from '@chakra-ui/react'

import { BucketsData, BucketsFact } from 'errors'
import { locales } from 'utils/i18n'
import { NeedHelpTextOnlyInfoBox } from 'components/HelpInfo/NeedHelpTextOnlyInfoBox'

import BulletPointList from '../components/BulletPointList'
import { ErrorHeading } from '../components/ErrorHeading'
import ICALink from '../components/ICALink'

import NotAvailableOrApplicableOrVerifiedError from './NotAvailableOrApplicableOrVerifiedError'
import { ErrorProps } from './types'

interface BucketsErrorProps extends ErrorProps {
  campaignName: string
  bucketsData?: BucketsData
}

type BucketsEligibilityErrors = Extract<
  BucketsFact,
  'CITIZENSHIP' | 'HOUSING_TYPE' | 'RESIDENTIAL_ADDRESS'
>

type KeysOfEligibilityHeaderTranslation =
  `bucketsError.eligibilityHeader.${keyof typeof locales['en']['error']['bucketsError']['eligibilityHeader']}`

const BUCKETS_ELIGIBILITY_HEADER_TRANSLATION: Record<
  BucketsEligibilityErrors,
  KeysOfEligibilityHeaderTranslation
> = {
  CITIZENSHIP: 'bucketsError.eligibilityHeader.citizenship',
  HOUSING_TYPE: 'bucketsError.eligibilityHeader.housingType',
  RESIDENTIAL_ADDRESS: 'bucketsError.eligibilityHeader.residentialAddress',
} as const

export const BucketsError = ({
  campaignName,
  bucketsData,
  t,
}: BucketsErrorProps) => {
  const displayAttribute = bucketsData?.displayAttribute ?? ''
  const expected = bucketsData?.expected ?? []
  const attribute = bucketsData?.attribute
  const isValid = Boolean(bucketsData?.valid)

  if (!isValid) {
    // Show generic buckets error screen
    return <NotAvailableOrApplicableOrVerifiedError t={t} />
  }

  // TODO: Improve to use typeguard or something to assert that attribute is now of a certain type
  // We are able to do this typed attribute as the other fields currently, such as name and uinfin will either always give a value or it is invalid
  const typedAttribute = attribute as BucketsEligibilityErrors
  const eligibilityHeader = t(
    BUCKETS_ELIGIBILITY_HEADER_TRANSLATION[typedAttribute],
  )

  const FACTS_TO_INFO_BOX = {
    CITIZENSHIP: (
      <Trans t={t} i18nKey={'bucketsError.helpInfo.citizenship'}>
        If you are eligible, and are unable to claim your vouchers, please reach
        out to
        <ICALink /> for help
      </Trans>
    ),
    HOUSING_TYPE: (
      <Trans t={t} i18nKey={'bucketsError.helpInfo.housingType'}>
        To update your registered address to your current Singaporean address,
        please go to
        <ICALink />
      </Trans>
    ),
    RESIDENTIAL_ADDRESS: (
      <Trans t={t} i18nKey={'bucketsError.helpInfo.residentialAddress'}>
        To update your registered address to your current Singaporean address,
        please go to
        <ICALink />
      </Trans>
    ),
  }

  return (
    <VStack divider={<Divider borderColor="neutral.300" />} spacing="24px">
      <ErrorHeading
        title={t('bucketsError.title', {
          campaignName,
          displayAttribute,
        })}
        componentContent={
          <VStack alignItems="start">
            <Text variant="bodyLight">{eligibilityHeader}</Text>
            <BulletPointList points={expected} />
          </VStack>
        }
      />
      <NeedHelpTextOnlyInfoBox>
        {(attribute === 'CITIZENSHIP' ||
          attribute === 'RESIDENTIAL_ADDRESS' ||
          attribute === 'HOUSING_TYPE') &&
          FACTS_TO_INFO_BOX[attribute]}
      </NeedHelpTextOnlyInfoBox>
    </VStack>
  )
}
