import { FallbackProps } from 'react-error-boundary'
import { useHistory } from 'react-router-dom'
import { Box, Image, useBreakpointValue, VStack } from '@chakra-ui/react'

import ErrorPageImagePng from 'images/error-buildings.png'
import ErrorPageImageSvg from 'images/error-buildings.svg'
import MobileWarnPng from 'images/image-warn.png'
import MobilerWarnSvg from 'images/image-warn.svg'
import SingpassLoadingPng from 'images/singpass-loading.png'
import SingpassLoadingSvg from 'images/singpass-loading.svg'
import type { CampaignDto } from 'constants/types'
import {
  hasMyinfoButNotEligibleError,
  isSignupError,
  SignupError,
} from 'errors'
import { useGetCampaignById } from 'hooks/Campaign'
import useScrollToTopOnRender from 'hooks/useScrollToTopOnRender'
import { DisplayContainer } from 'components/DisplayContainer'
import { Footer } from 'components/Footer'
import { HeaderBar } from 'components/HeaderBar'
import { Loading } from 'components/Loading'

import { ErrorBoundary } from 'pages/ErrorBoundary/ErrorBoundary'

import MyinfoShownBeforeErrorBoundary from './MyinfoShownBeforeErrorBoundary'

type ErrorBoundaryWrapperProps = {
  children: React.ReactNode
  campaign: CampaignDto | undefined
}

const ErrorBoundaryWrapper = ({
  children,
  campaign,
}: ErrorBoundaryWrapperProps) => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })
  return (
    <DisplayContainer
      heroImageSrc={ErrorPageImageSvg}
      heroImageSrcFallback={ErrorPageImagePng}
    >
      <HeaderBar logoImageSrc={campaign?.logoUrl} />
      <VStack align="stretch" spacing={{ base: '24px', lg: '16px' }}>
        {isMobileWidth && (
          <Box>
            <Image
              width="72px"
              marginTop="16px"
              src={MobilerWarnSvg}
              fallbackSrc={MobileWarnPng}
              alt="Custom header image"
            />
          </Box>
        )}
        {children}
      </VStack>
    </DisplayContainer>
  )
}

/**
 * The ErrorBoundaryContainer has two main flow.
 * - The first is to display a myinfo page prior to navigating to the ErrorBoundaryPage.
 * - The second is to go straight to the ErrorBoundaryPage.
 * The additional myinfo page is necessary to fulfill a functional requirement based on a policy. Specifically,
 * if myinfo data is successfully retrieved entirely, it must be displayed to the user. Please refer to this
 * miro flow (@see https://miro.com/app/board/o9J_l8ZLcUY=/?moveToWidget=3458764550467380485&cot=14)
 * to determine which errors require following the first flow (located under the group name "ineligible errors").
 */
export const ErrorBoundaryContainer = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  useScrollToTopOnRender()
  const history = useHistory()

  const { campaignData, isLoading } = useGetCampaignById({
    campaignId:
      isSignupError(error) && error.campaignId ? error.campaignId : '',
  })

  const currentErrorType = (err: unknown): SignupError => {
    if (isSignupError(err)) {
      return err.type
    } else {
      // logs error for datadog RUM
      console.error('Unknown err', err)
      return 'boundary_error'
    }
  }

  const handleRedirectLogin = () => {
    if (isSignupError(error) && error.campaignId) {
      resetErrorBoundary()
      history.push(`/${error.campaignId}`)
    }
  }
  const errorType = currentErrorType(error)
  const campaign =
    isSignupError(error) && error.campaign ? error.campaign : campaignData
  const myinfoData =
    isSignupError(error) && error.myinfoData ? error.myinfoData : undefined
  const claimedVoucherData =
    isSignupError(error) && error.claimedVoucherData
      ? error.claimedVoucherData
      : undefined
  const bucketsData =
    isSignupError(error) && error.bucketsData ? error.bucketsData : undefined

  /**
   * The return structure look similar and can be extracted into a component to reuse. The reason why it wasnt done it such as way is because we want to avoid
   * props drilling. By using component composition, I can avoid passing from A -> B -> C whereby B doesnt use the props at all. Currently this component is able
   * to directly pass props to error boundary without having to pass to the wrapper first. Tho there is some duplication, but its not so bad while it avoid props
   * drilling entirely.
   */
  return (
    <>
      {isLoading && (
        <Loading
          title={'myInfoLoading'}
          imageSrc={SingpassLoadingSvg}
          fallbackImageSrc={SingpassLoadingPng}
        />
      )}
      {!isLoading && hasMyinfoButNotEligibleError(errorType) && (
        <MyinfoShownBeforeErrorBoundary
          claimedVoucherData={claimedVoucherData}
          myinfoData={myinfoData}
          campaignData={campaign}
        >
          <ErrorBoundaryWrapper campaign={campaign}>
            <ErrorBoundary
              errorType={errorType}
              handleRedirectLogin={handleRedirectLogin}
              claimedVoucherData={claimedVoucherData}
              campaign={campaign}
              bucketsData={bucketsData}
            />
          </ErrorBoundaryWrapper>
        </MyinfoShownBeforeErrorBoundary>
      )}
      {!isLoading && !hasMyinfoButNotEligibleError(errorType) && (
        <ErrorBoundaryWrapper campaign={campaign}>
          <ErrorBoundary
            errorType={errorType}
            handleRedirectLogin={handleRedirectLogin}
            claimedVoucherData={claimedVoucherData}
            campaign={campaign}
            bucketsData={bucketsData}
          />
        </ErrorBoundaryWrapper>
      )}
      <Footer />
    </>
  )
}
