import { Redirect } from 'react-router-dom'

import SingpassLoadingPng from 'images/singpass-loading.png'
import SingpassLoadingSvg from 'images/singpass-loading.svg'
import { useGetCampaignById } from 'hooks/Campaign'
import { usePersonCallback } from 'hooks/MyinfoCallback'
import { Loading } from 'components/Loading'

export const CallbackPage = () => {
  const {
    personData,
    isLoading: isLoadingPerson,
    campaignId,
  } = usePersonCallback()

  // retrieves campaign data only when campaignId resolved from callback
  const { campaignData, isLoading: isLoadingCampaign } = useGetCampaignById({
    campaignId,
  })

  return isLoadingCampaign ||
    isLoadingPerson ||
    !campaignData ||
    !personData ? (
    <Loading
      title={'myInfoLoading'}
      imageSrc={SingpassLoadingSvg}
      fallbackImageSrc={SingpassLoadingPng}
    />
  ) : (
    <Redirect to={`${campaignData.id}/form/details`} />
  )
}
