import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { SystemStyleInterpolation } from '@chakra-ui/theme-tools'

import { textStyles } from '../textStyles'

export type ModalLinkVariants = 'mobile' | 'desktop'

export const ModalLink: Omit<ComponentMultiStyleConfig, 'variants'> & {
  variants: Record<ModalLinkVariants, SystemStyleInterpolation>
} = {
  parts: ['container', 'title', 'body', 'button', 'infoContainer'],
  baseStyle: {
    container: {
      paddingX: '32px',
    },
    title: {
      ...textStyles.h3,
      color: 'primary.700',
    },
    body: {
      ...textStyles.body1,
      alignItems: 'stretch',
      marginTop: '16px',
    },
    button: {
      marginTop: '24px',
    },
    infoContainer: {
      marginTop: '24px',
    },
  },
  variants: {
    mobile: {
      container: {
        borderTopRadius: '32px',
        paddingY: '24px',
      },
      title: {
        marginTop: '8px',
      },
    },
    desktop: {
      container: {
        paddingY: '32px',
        minWidth: '680px',
        boxShadow: '0px 0px 10px rgba(216, 222, 235, 0.5)',
        borderRadius: '4px',
      },
      infoContainer: {
        padding: '20px',
        backgroundColor: 'primary.100',
      },
    },
  },
  defaultProps: {
    variant: 'mobile',
  },
}
