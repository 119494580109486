import { useTranslation } from 'react-i18next'
import { Box, Center, Image, Text, VStack } from '@chakra-ui/react'

import CampaignListingEmptySvg from 'images/campaign-listing-empty.svg'

export const NoCampaignsContainer = () => {
  const { t } = useTranslation('listing', { keyPrefix: 'noCampaignCard' })

  return (
    <Center width="100%" flexGrow={1} marginY="80px">
      <VStack spacing={{ base: '16px', lg: '24px' }}>
        <Box>
          <Image
            width="132px"
            height="100px"
            src={CampaignListingEmptySvg}
            alt="No vouchers header image"
          />
        </Box>

        <VStack
          paddingX="60px"
          alignItems="center"
          spacing={{ base: '4px', lg: '8px' }}
          textAlign="center"
        >
          <Text
            color="primary.500"
            textStyle={{ base: 'subhead1', lg: 'h3' }}
            id="listing-error-header"
          >
            {t('title')}
          </Text>
          <Text
            color="neutral.900"
            id="listing-error-content"
            textStyle={{ base: 'caption2', lg: 'body1' }}
            paddingX="36px"
          >
            {t('description')}
          </Text>
        </VStack>
      </VStack>
    </Center>
  )
}
