import { Trans } from 'react-i18next'
import { Divider, Text, VStack } from '@chakra-ui/react'

import { NeedHelpTextOnlyInfoBox } from 'components/HelpInfo/NeedHelpTextOnlyInfoBox'

import BulletPointList from 'pages/ErrorBoundary/components/BulletPointList'
import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

import ICALink from '../components/ICALink'

interface InvalidHDBTypeErrorProps extends ErrorProps {
  campaignName: string
  eligibilityCriteria: string[]
}

export const InvalidHDBTypeError = ({
  t,
  campaignName,
  eligibilityCriteria,
}: InvalidHDBTypeErrorProps) => {
  return (
    <VStack divider={<Divider borderColor="neutral.300" />} spacing="24px">
      <ErrorHeading
        title={t('invalidHdbType.title', { campaignName })}
        componentContent={
          <VStack alignItems="start">
            <Text variant="bodyLight">
              <Trans t={t} i18nKey={'invalidHdbType.eligibilityHeader'}>
                To be eligible to claim, you registered under a:
              </Trans>
            </Text>
            <BulletPointList points={eligibilityCriteria} />
          </VStack>
        }
      />
      <NeedHelpTextOnlyInfoBox>
        <Trans t={t} i18nKey={'invalidHdbType.helpInfo'}>
          To update your registered address to your current Singaporean address,
          please go to
          <ICALink />
        </Trans>
      </NeedHelpTextOnlyInfoBox>
    </VStack>
  )
}
