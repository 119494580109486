import { useState } from 'react'

interface UseControlledInputParams {
  initialValue: string
}

function useControlledInput({ initialValue }: UseControlledInputParams) {
  const [value, setValue] = useState(initialValue)
  const [errorValue, setErrorValue] = useState('')

  const setInputError = ({
    message,
    keepValue = false,
  }: {
    message: string
    keepValue?: boolean
  }) => {
    if (!keepValue) {
      resetField()
    }
    setErrorValue(message)
  }

  const setValidValue = (value: string) => {
    setValue(value)
    setErrorValue('')
  }

  const resetField = () => {
    setValue('')
  }

  return {
    value,
    inputError: errorValue,
    setInputError,
    setValidValue,
    resetField,
  }
}

export default useControlledInput
