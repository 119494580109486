import { BiRightArrowAlt } from 'react-icons/bi'
import { Button } from '@chakra-ui/react'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

interface SingpassAccessDeniedErrorProps extends ErrorProps {
  handleRedirectLogin: () => void
}

export const SingpassAccessDeniedError = ({
  t,
  handleRedirectLogin,
}: SingpassAccessDeniedErrorProps) => (
  <>
    <ErrorHeading
      title={t('failMyInfoRetrieval.title')}
      textContent={t('failMyInfoRetrieval.info')}
    />
    <Button
      onClick={handleRedirectLogin}
      rightIcon={<BiRightArrowAlt />}
      children={t('buttons.returnLogin')}
    />
  </>
)
