import { useTranslation } from 'react-i18next'
import { Box, HStack, Text, useBreakpointValue } from '@chakra-ui/react'

import { ReactComponent as QuestionMark } from 'images/question-mark.svg'
import { CampaignDto } from 'constants/types'
import { DDAction } from 'utils/monitoring'
import { ForMoreHelpInfoBox } from 'components/HelpInfo/ForMoreHelpInfoBox'
import HelpInfoOrganiserContent from 'components/HelpInfo/HelpInfoOrganiserContent'
import LinkModal from 'components/LinkModal'

interface PhoneFormHelpProps {
  campaign: CampaignDto
}

export const PhoneFormHelp = ({ campaign }: PhoneFormHelpProps) => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })
  const { t } = useTranslation(['contact', 'common'])

  return isMobileWidth ? (
    <Box alignSelf="center">
      <LinkModal
        linkText={t('common:needHelp')}
        linkVariant="subhead"
        closeButtonDDActionName={DDAction.CLOSE_NEED_HELP_BUTTON_PHONE}
        ddActionName={DDAction.NEED_HELP_BUTTON_PHONE}
        modalContent={<HelpInfoOrganiserContent campaign={campaign} />}
        headerText={
          <HStack spacing="12px">
            <QuestionMark color="#2F3476" width="18px" height="18px" />
            <Text textStyle="h4" color="primary.700">
              {t('contact:moreHelpHeading')}
            </Text>
          </HStack>
        }
      />
    </Box>
  ) : (
    <ForMoreHelpInfoBox>
      <HelpInfoOrganiserContent campaign={campaign} />
    </ForMoreHelpInfoBox>
  )
}
