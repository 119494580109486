import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

import { components } from './components'
import { foundations } from './foundations'
import { textStyles } from './textStyles'

export const theme = extendTheme(
  {
    ...foundations,
    components,
    textStyles,
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
)
