import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Implemented from 'react-router' guide
// https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top (outdated)
export default function ScrollToTop(): null {
  const { pathname } = useLocation()

  useEffect(() => {
    // Read more here https://developer.chrome.com/blog/history-api-scroll-restoration.
    // Due to some newer browser api, it affected and block our scroll restoration!
    // Reference https://github.com/remix-run/react-router/blob/9e7486b89e712b765d947297f228650cdc0c488e/packages/react-router-dom/index.tsx#L1753
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    window.scrollTo(0, 0)

    return () => {
      window.history.scrollRestoration = 'auto'
    }
  }, [pathname])

  return null
}
