import { ComponentMultiStyleConfig } from '@chakra-ui/react'

import { textStyles } from '../textStyles'

export const FormInput: ComponentMultiStyleConfig = {
  parts: ['input', 'error', 'helper', 'button'],
  baseStyle: {
    error: {
      ...textStyles.body2,
      alignItems: 'baseline',
      color: 'danger.500',
    },
    helper: {
      ...textStyles.subhead2,
      textAlign: 'center',
      marginTop: '12px',
      color: 'neutral.800',
    },
    button: {
      textDecoration: 'underline',
      alignSelf: 'center',
    },
  },
  variants: {
    phone: {
      input: {
        ...textStyles.body1,
        height: '44px',
      },
    },
    otp: {
      input: {
        height: '56px',
        fontSize: '1.5rem',
        textStyle: 'h3',
      },
    },
  },
}
