import { useCallback, useState } from 'react'

import useInterval from './useInterval'

interface UseTimerParams {
  duration: number // value, in seconds, for timeout
  autoStart?: boolean // boolean to indicate if timer should start running upon invocation of hook
  callbackFn?: (timer: number) => void // optional callback function on every interval
  callbackFnOnTimerEnd?: () => void // optional callback function when timer reaches 0
}

function useTimer({
  duration,
  autoStart = false,
  callbackFn,
  callbackFnOnTimerEnd,
}: UseTimerParams) {
  // State to keep count of current value of timer
  const [count, setCount] = useState(duration)
  const [isTimerRunning, setIsTimerRunning] = useState(autoStart)

  const startCountdown = () => {
    setCount(duration)
    setIsTimerRunning(true)

    // This is to prevent lag between starting timer
    // and invoking callback function based on the
    // timer count
    callbackFn?.(duration)
  }

  const countdownCallback = useCallback(() => {
    setCount((prevCount) => {
      const newCount = prevCount - 1

      callbackFn?.(newCount)

      if (newCount === 0) {
        callbackFnOnTimerEnd?.()
        setIsTimerRunning(false)
      }

      return newCount
    })
  }, [callbackFn, callbackFnOnTimerEnd])

  useInterval(countdownCallback, isTimerRunning ? 1000 : null)

  return { timer: count, startCountdown, isTimerRunning }
}

export default useTimer
