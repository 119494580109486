import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BiChevronDown, BiChevronUp, BiGlobe } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'constants/translation'
import { changeLanguage } from 'utils/i18n'
import { DDAction } from 'utils/monitoring'

interface LanguageSelectorProps {
  variant?: 'dark'
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ variant }) => {
  const { i18n } = useTranslation()
  // Get selected locale information (label). If it is not found, then
  // default locale (en) will be assumed to be selected.
  const selectedLocale =
    SUPPORTED_LOCALES.find(
      // Get base language from locale
      (locale) => locale.code === i18n?.language?.substring(0, 2), // en-US -> en
    ) || DEFAULT_LOCALE
  const history = useHistory()

  const styles = useMultiStyleConfig('Menu', { variant })

  return (
    <Menu offset={[0, 0]} autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            sx={styles.button}
            as={Button}
            leftIcon={<Icon as={BiGlobe} sx={styles.icon} />}
            rightIcon={
              isOpen ? <Icon as={BiChevronUp} /> : <Icon as={BiChevronDown} />
            }
            variant="outline"
            iconSpacing="9.5px"
            aria-label="language selector"
            data-dd-action-name={DDAction.CHANGE_LANGUAGE_BUTTON}
          >
            <Text sx={styles.label}>{selectedLocale.label}</Text>
          </MenuButton>
          <MenuList sx={styles.list} rootProps={{ style: { width: '100%' } }}>
            {SUPPORTED_LOCALES.map((locale) => (
              <MenuItem
                isFocusable={false}
                key={locale.code}
                sx={styles.item}
                onClick={() => changeLanguage(locale.code, history)}
                backgroundColor={
                  selectedLocale.code === locale.code ? 'primary.200' : 'none'
                }
                aria-label={locale.code}
              >
                {locale.label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
