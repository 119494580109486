import { FC } from 'react'
import { TFunction } from 'react-i18next'
import { Box, Text } from '@chakra-ui/react'

interface CampaignHeaderSectionProps {
  t: TFunction<'login', undefined>
  campaignName: string
  organiserName: string
}

export const CampaignHeaderSection: FC<CampaignHeaderSectionProps> = ({
  t,
  campaignName,
  organiserName,
}) => {
  return (
    <Box>
      <Text textStyle="h3" color="primary.700">
        {campaignName}
      </Text>
      <Text variant="caption" mt={{ base: '8px', lg: '16px' }}>
        {t('supportedBy', { organiserName })}
      </Text>
    </Box>
  )
}
