import { Trans, useTranslation } from 'react-i18next'
import { BiRightArrowAlt } from 'react-icons/bi'
import {
  Button,
  Center,
  Divider,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'

import ClaimBeforeImagePng from 'images/claim-again-success.png'
import ClaimBeforeImageSvg from 'images/claim-again-success.svg'
import ClaimImagePng from 'images/claim-success.png'
import ClaimImageSvg from 'images/claim-success.svg'
import { CampaignDto } from 'constants/types'
import { formatAreaCodeContact } from 'utils/helpers'
import { DDAction } from 'utils/monitoring'
import { Heading } from 'components/Heading'
import HelpInfoOrganiserContent from 'components/HelpInfo/HelpInfoOrganiserContent'
import { NeedHelpInfoBox } from 'components/HelpInfo/NeedHelpInfoBox'
import LinkModal from 'components/LinkModal'

interface SuccessPageProps {
  voucherUrl: string
  contactNumber: string
  isClaimedBefore?: boolean
  campaign: CampaignDto
}

export const SuccessPage = ({
  voucherUrl,
  contactNumber,
  isClaimedBefore,
  campaign,
}: SuccessPageProps) => {
  const { t } = useTranslation('success')

  return (
    <>
      <Center paddingX="54.5px" display={{ base: 'block', lg: 'none' }}>
        <Image
          src={isClaimedBefore ? ClaimBeforeImageSvg : ClaimImageSvg}
          loading="eager"
          fallbackSrc={isClaimedBefore ? ClaimBeforeImagePng : ClaimImagePng}
          css={{ '-webkit-transform': 'translate3d(0,0,0)' }}
          alt="Custom header image"
        />
      </Center>

      <Heading
        id="voucher-page-header"
        title={
          isClaimedBefore ? t('claimedBeforeTitle') : t('claimSuccessTitle')
        }
        isBolded
      />
      <VStack
        textStyle="body1"
        spacing="8px"
        align="stretch"
        color="neutral.800"
      >
        <Text>
          <Trans
            t={t}
            i18nKey={'claimSuccessSentInfo'}
            values={{
              contactNumber: `${formatAreaCodeContact(contactNumber)}`,
            }}
          >
            We have also sent an sms to contactNumber with a link to access your
            vouchers. You can also tap the button below to see them.
          </Trans>
        </Text>
      </VStack>
      <VStack spacing="16px">
        <Link href={voucherUrl} width="100%">
          <Button
            rightIcon={<BiRightArrowAlt />}
            variant="primary"
            data-dd-action-name={DDAction.VIEW_VOUCHER_BUTTON}
            children={t('showVouchers')}
          />
        </Link>
        <LinkModal
          linkText={t('title')}
          headerText={t('title')}
          linkVariant="subhead"
          closeButtonDDActionName={DDAction.CLOSE_SMS_NOT_RECEIVED_BUTTON}
          ddActionName={DDAction.DID_NOT_RECEIVE_SMS_BUTTON}
          modalContent={
            <VStack spacing="16px" align="start" width="100%">
              <VStack
                align="start"
                divider={<Divider borderColor="neutral.300" />}
                spacing="24px"
                width="100%"
              >
                <VStack
                  textStyle="body1"
                  color="neutral.800"
                  spacing="20px"
                  align="start"
                >
                  <Text>{t('issue')}</Text>
                  <Text>
                    <Trans t={t} i18nKey={'suggestion'}>
                      {
                        'In the meantime, you can still view and spend your vouchers with the '
                      }
                      <Link
                        variant="subhead"
                        href={voucherUrl}
                        textDecoration="underline"
                      >
                        voucher link
                      </Link>
                      .
                    </Trans>
                  </Text>
                </VStack>
                <NeedHelpInfoBox>
                  <HelpInfoOrganiserContent campaign={campaign} />
                </NeedHelpInfoBox>
              </VStack>
            </VStack>
          }
        />
      </VStack>
    </>
  )
}
