import { FC } from 'react'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Image, Spinner } from '@chakra-ui/react'

import useScrollToTopOnRender from 'hooks/useScrollToTopOnRender'
import { Heading } from 'components/Heading'

export interface LoadingProps {
  title: TFuncKey<'loading'>
  content?: TFuncKey<'loading'>
  imageSrc?: string
  fallbackImageSrc?: string
}

export const Loading: FC<LoadingProps> = ({
  title,
  content,
  imageSrc,
  fallbackImageSrc,
}) => {
  const { t } = useTranslation('loading')

  // Force screen to return to top on render
  useScrollToTopOnRender()

  return (
    <>
      <Spinner thickness="3px" />
      <Heading title={t(title)} content={content && t(content)} isBolded />
      {imageSrc && fallbackImageSrc && (
        <Image
          src={imageSrc}
          fallbackSrc={fallbackImageSrc}
          alt="User loading activity"
          loading="eager"
        />
      )}
    </>
  )
}
