import { BiRightArrowAlt } from 'react-icons/bi'
import { Button } from '@chakra-ui/react'
import _ from 'lodash'

import { CampaignDto } from 'constants/types'
import { ClaimedVoucherDataParams } from 'errors'
import { DetailsPane } from 'components/DetailsPane'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { ErrorProps } from 'pages/ErrorBoundary/templates/types'

import NeedHelpErrorComponent from '../components/NeedHelpErrorComponent'

interface InvalidAlreadyClaimedErrorProps extends ErrorProps {
  claimedVoucherData: ClaimedVoucherDataParams
  campaign: CampaignDto
  handleRedirectLogin: () => void
}

// The Nric here is the claimant and not the actual person who already claimed
// Replace the name with the previousClaimantName to show the previousClaimanatName at the end
function formatClaimedVoucherDataToShowCurrentClaimant(
  claimedVoucherData: ClaimedVoucherDataParams,
) {
  return {
    ..._.omit(claimedVoucherData, 'nric'),
    name: claimedVoucherData.previousClaimantName,
  }
}

// This error is for 2 member in a household claiming the voucher digitally
export const InvalidAlreadyClaimedError = ({
  t,
  claimedVoucherData,
  campaign,
  handleRedirectLogin,
}: InvalidAlreadyClaimedErrorProps) => (
  <>
    <ErrorHeading title={t('voucherAlreadyClaimed')} />
    <DetailsPane
      {...formatClaimedVoucherDataToShowCurrentClaimant(claimedVoucherData)}
    />
    <Button
      onClick={handleRedirectLogin}
      rightIcon={<BiRightArrowAlt />}
      children={t('buttons.returnLogin')}
    />
    {campaign && <NeedHelpErrorComponent campaign={campaign} />}
  </>
)
