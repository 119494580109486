import { useErrorHandler } from 'react-error-boundary'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Divider, useBreakpointValue, VStack } from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { useCampaignContext } from 'context/CampaignContext'

import { RATE_LIMIT_INTERVAL_IN_SECONDS } from 'constants/config'
import { FormPhase } from 'constants/types'
import { isSignupError } from 'errors'
import { useRegisterOtp } from 'hooks/Otp'
import useTimer from 'hooks/useTimer'
import { isClientError, isTooManyRequestsError } from 'utils/service'

import { PhoneFormHelp } from 'pages/PhoneForm/components/PhoneFormHelp'
import { PhoneFormPage } from 'pages/PhoneForm/PhoneFormPage'

export const PhoneFormPageContainer = () => {
  const isMobileWidth = useBreakpointValue({ base: true, lg: false })
  const { setPhoneState, setFormPhase } = useAuth()
  const history = useHistory()
  const { campaign, routeCampaignId } = useCampaignContext()
  const handleError = useErrorHandler()
  const { i18n } = useTranslation()

  const {
    register,
    handleSubmit,
    setError: setInputError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<{ phone: string }>()

  const { mutate: registerOtp, isLoading: isLoadingRegisterOtp } =
    useRegisterOtp()

  const rateLimitTimerErrorCallback = (timer: number) => {
    setInputError('phone', {
      message: `Please wait another ${timer} seconds before trying to request for another OTP again.`,
    })
  }

  const onTimerEndCallback = () => {
    clearErrors('phone')
  }

  const { timer, startCountdown, isTimerRunning } = useTimer({
    duration: RATE_LIMIT_INTERVAL_IN_SECONDS,
    callbackFn: rateLimitTimerErrorCallback,
    callbackFnOnTimerEnd: onTimerEndCallback,
  })

  const isButtonDisabled = timer >= 0 && isTimerRunning

  const onSubmit: SubmitHandler<{ phone: string }> = async (data) => {
    const formattedPhone = `65${data.phone}`
    registerOtp(
      {
        phone: formattedPhone,
        campaignId: routeCampaignId,
        lang: i18n.language,
      },
      {
        onSuccess: () => {
          setPhoneState(formattedPhone)
          setFormPhase(FormPhase.ENTER_OTP)
          history.push(`/${routeCampaignId}/form/verify`)
        },
        onError: (err) => {
          if (!isSignupError(err) && isClientError(err) && err.response) {
            if (isTooManyRequestsError(err)) {
              return startCountdown()
            }
            return setInputError('phone', {
              message: err.response.data.error.message,
            })
          }
          return handleError(err)
        },
      },
    )
  }

  return (
    <VStack spacing={isMobileWidth ? '16px' : '24px'} width="100%">
      <PhoneFormPage
        handlePhoneFormSubmit={handleSubmit(onSubmit)}
        register={register}
        isSubmitting={isSubmitting || isLoadingRegisterOtp}
        phoneFieldError={errors.phone}
        isButtonDisabled={isButtonDisabled}
      />
      {!isMobileWidth && <Divider borderColor="neutral.300" />}
      {campaign && <PhoneFormHelp campaign={campaign} />}
    </VStack>
  )
}
