import { Center, Spinner, Text, VStack } from '@chakra-ui/react'

export const CampaignCardsLoading = () => {
  return (
    <Center flex={1}>
      <VStack spacing={{ base: '12px', lg: '24px' }}>
        <Spinner
          color="primary.700"
          thickness="6px"
          boxSize={{ base: '72px', lg: '88px' }}
        />
        <Text textStyle={{ base: 'h5', lg: 'h4' }} color="neutral.800">
          Loading...
        </Text>
      </VStack>
    </Center>
  )
}
