import { Image, Text, VStack } from '@chakra-ui/react'

import redeemLogo from 'images/redeemsg-full.png'
import { DisplayContainer } from 'components/DisplayContainer'

import { ErrorHeading } from 'pages/ErrorBoundary/components/ErrorHeading'
import { LoginImageMobile } from 'pages/Login/components/LoginImageMobile'

type SingpassDownErrorProps = {
  title: string
  message: string
}

export const SingpassDownError = ({
  title,
  message,
}: SingpassDownErrorProps) => {
  return (
    <DisplayContainer>
      <Image
        display={'block'}
        maxW="128px"
        maxH={'38px'}
        src={redeemLogo}
        fallbackSrc={redeemLogo}
        alt="Organisation Logo"
      />
      <VStack
        align="stretch"
        spacing={{ base: '24px', lg: '16px' }}
        paddingTop="16px"
      >
        <ErrorHeading
          title={title}
          componentContent={<Text variant="bodyLight">{message}</Text>}
        />
        <LoginImageMobile />
      </VStack>
    </DisplayContainer>
  )
}
