import { useTranslation } from 'react-i18next'
import { useAuth } from 'context/AuthContext'
import { useCampaignContext } from 'context/CampaignContext'

import { SuccessPage } from 'pages/Success/SuccessPage'

export const SuccessPageContainer = () => {
  const { i18n } = useTranslation('success')
  const { phone, voucherUrl, person } = useAuth()
  const { campaign } = useCampaignContext()

  const voucherUrlWithLang = i18n.language
    ? `${voucherUrl}?lang=${i18n.language}`
    : voucherUrl

  // TODO: Temporary fix
  if (!campaign) {
    throw new Error('campaign retrieval from context failed')
  }

  return (
    <SuccessPage
      voucherUrl={voucherUrlWithLang}
      contactNumber={phone}
      campaign={campaign}
      isClaimedBefore={person?.isExisting}
    />
  )
}
