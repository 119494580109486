import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VStack } from '@chakra-ui/react'
import { useCampaignContext } from 'context/CampaignContext'

import { SignupException } from 'errors'
import { useJSONFlags } from 'hooks/FeatureFlag'
import { addDelay } from 'utils/helpers'
import { authorise } from 'services/MyInfoApi'
import { RedeemBanner } from 'components/Branding/RedeemBanner'
import { DisplayContainer } from 'components/DisplayContainer'
import { HeaderBar } from 'components/HeaderBar'
import { Loading, LoadingProps } from 'components/Loading'

import { SingpassDownError } from 'pages/ErrorBoundary/templates/SingpassDown'
import { LoginPage } from 'pages/Login/LoginPage'

type FeatureFlagSingpassDownProps = {
  signupSingpassDownFeatureFlag: {
    title?: string
    message?: string
    shouldShow: boolean
  }
}

export const LoginPageContainer = () => {
  const { campaign } = useCampaignContext()
  const { i18n } = useTranslation('login')

  // Custom states for dealing with loading
  const [isRedirectLoading, setIsRedirectLoading] = useState(false)
  const [loadingObject, setLoadingObject] = useState<LoadingProps>({
    title: 'campaignLoading',
  })

  const handleLogin = async () => {
    if (!campaign) {
      throw new SignupException({
        type: 'campaign_not_found',
      })
    }

    setLoadingObject({
      title: 'redirectSingpassTitle',
      content: 'redirectSingpassInfo',
    })
    setIsRedirectLoading(true)

    // For UI/UX purposes so that users know that they are being redirected to Singpass
    await addDelay(1000)
    return authorise({
      campaign: campaign.id,
      localeCode: i18n.language,
      singpassEserviceId: campaign.singpassEserviceId,
    })
  }

  const featureFlagValues = useJSONFlags<FeatureFlagSingpassDownProps>(
    'signupSingpassDownFeatureFlag',
  )
  const {
    title,
    message,
    shouldShow: shouldShowSingpassDown,
  } = featureFlagValues

  // Login page has a completely custom view
  return shouldShowSingpassDown ? (
    <SingpassDownError title={title || ''} message={message || ''} />
  ) : (
    <DisplayContainer
      mobileBannerContent={!isRedirectLoading && <RedeemBanner />}
    >
      <HeaderBar logoImageSrc={campaign?.logoUrl} />
      <VStack align="stretch" spacing={{ base: '24px', lg: '16px' }}>
        {!campaign || isRedirectLoading ? (
          <Loading {...loadingObject} />
        ) : (
          <LoginPage campaign={campaign} handleLogin={handleLogin} />
        )}
      </VStack>
    </DisplayContainer>
  )
}
