import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiLogInCircle, BiRightArrowAlt } from 'react-icons/bi'
import {
  Box,
  Button,
  Center,
  Divider,
  Link,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'

import { CampaignDto } from 'constants/types'
import { customMonitorClick } from 'utils/additionalMonitoring'
import { DDAction } from 'utils/monitoring'
import { ForMoreHelpInfoBox } from 'components/HelpInfo/ForMoreHelpInfoBox'
import HelpInfoOrganiserContent from 'components/HelpInfo/HelpInfoOrganiserContent'

import { CampaignContentSection } from './components/CampaignContentSection'
import { CampaignHeaderSection } from './components/CampaignHeaderSection'
import { LoginImageMobile } from './components/LoginImageMobile'

interface LoginPageProps {
  campaign: CampaignDto
  handleLogin: () => void
}

export const LoginPage: FC<LoginPageProps> = ({ campaign, handleLogin }) => {
  const { t } = useTranslation('login')

  const isMobileWidth = useBreakpointValue({ base: true, lg: false })
  const tncUrl = campaign.tncUrl

  return (
    <>
      <CampaignHeaderSection
        t={t}
        campaignName={campaign.name}
        organiserName={campaign.organiserName}
      />
      <CampaignContentSection
        t={t}
        voucherTotalAmount={campaign.voucherTotalAmount}
        eligibilityType={campaign.eligibility}
      />
      <Button
        variant="primary"
        data-dd-action-name={DDAction.LOGIN_SINGPASS_BUTTON}
        rightIcon={<BiLogInCircle />}
        onClick={handleLogin}
        children={t('loginButtonLabel')}
      />
      {/* Stack items are not block element, hence the margin collapse does not work. Thus, we need to do 40 - 24px to have a total spacing of 40px to the image  */}
      {!!tncUrl && (
        <Center
          margin={
            isMobileWidth ? '20px 0 16px !important' : '20px 0 0px !important'
          }
        >
          <Text
            textStyle="legal"
            as="span"
            textAlign="center"
            id="campaign-tnc-url"
          >
            <Trans t={t} i18nKey={'termsAndConditions'}>
              {'By continuing, you accept the '}
              <Link
                href={tncUrl}
                isExternal
                data-dd-action-name={DDAction.TERMS_AND_CONDITIONS_BUTTON}
              >
                terms and conditions
              </Link>
            </Trans>
          </Text>
        </Center>
      )}
      {isMobileWidth && (
        <Box marginTop="24px !important">
          <LoginImageMobile />
        </Box>
      )}
      {/* https://github.com/chakra-ui/chakra-ui/issues/5108 StackItem is insufficient to overwrite the margin */}
      <VStack marginTop="24px !important" spacing="24px" align="start">
        <Divider borderColor="neutral.300" marginX="0" />
        {!!campaign.advisoryUrl && (
          <Link
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            color="primary.500"
            onClick={() => {
              customMonitorClick(DDAction.ADVISORY_LINK_BUTTON)
            }}
            href={campaign.advisoryUrl}
            cursor="pointer"
            display="flex"
            isExternal
          >
            <Text textStyle="subhead1" as="span">
              {t('moreInfo', { campaignName: campaign.name })}
            </Text>
            <BiRightArrowAlt />
          </Link>
        )}

        <ForMoreHelpInfoBox>
          <HelpInfoOrganiserContent campaign={campaign} />
        </ForMoreHelpInfoBox>
      </VStack>
    </>
  )
}
