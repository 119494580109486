import type { ComponentStyleConfig } from '@chakra-ui/react'

export const Text: ComponentStyleConfig = {
  baseStyle: {
    textStyle: 'body1',
    color: 'neutral.900',
  },

  variants: {
    boldTitle: {
      textStyle: { base: 'h4', lg: 'h3' },
    },
    title: {
      textStyle: { base: 'h5', lg: 'h3' },
    },

    bodyLight: {
      color: 'neutral.700',
    },

    caption: {
      textStyle: { base: 'caption2', lg: 'body2' },
      color: 'neutral.700',
    },
    contactFooter: {
      textStyle: { base: 'caption1', lg: 'subhead1' },
    },
    default: {
      color: 'inherit',
      textStyle: 'none',
    },
  },
  // defaults to inheriting parent's style
  defaultProps: {
    variant: 'default',
  },
}
