// icon:bi-chevron-up | Boxicons https://boxicons.com/ | Atisa

import { chakra } from '@chakra-ui/react'

export const BiChevronUp = chakra((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M6.293 13.293l1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z" />
    </svg>
  )
})
