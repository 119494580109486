import { initReactI18next } from 'react-i18next'
import { History } from 'history'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import queryString from 'query-string'
import { translationsEN } from 'translations/en'
import { translationsMS } from 'translations/ms'
import { translationsTA } from 'translations/ta'
import { translationsZH } from 'translations/zh'

import {
  QUERY_STRING_LOCALE_KEY,
  SUPPORTED_LOCALES,
} from 'constants/translation'

export const locales = {
  en: { ...translationsEN },
  ms: { ...translationsMS },
  zh: { ...translationsZH },
  ta: { ...translationsTA },
}

// For providing strong typed translations
// See: https://react.i18next.com/latest/typescript
declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common'
    // take type reference from en locale
    resources: typeof locales.en
  }
}

/**
 * Language translation init
 */
// eslint-disable-next-line import/prefer-default-export
export function initTranslations(): void {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      detection: {
        // Read manually configured locale in query string
        order: ['querystring'],
        lookupQuerystring: QUERY_STRING_LOCALE_KEY,
        caches: [], // Disable caching of language in localStorage so it always reads from querystring
      },
      resources: locales,
      supportedLngs: SUPPORTED_LOCALES.map((locale) => locale.code),
      nonExplicitSupportedLngs: true, // Support all locales with supported prefixes
      fallbackLng: 'en',
      returnEmptyString: false,
    })
}

export function changeLanguage(localeCode: string, history: History): void {
  i18n.changeLanguage(localeCode)
  history.replace({
    search: queryString.stringify({
      ...queryString.parse(history.location.search),
      [QUERY_STRING_LOCALE_KEY]: localeCode,
    }),
  })
}
