import { Route, Switch } from 'react-router-dom'
import CampaignRouter from 'app/CampaignRouter'

import { DisplayContainer } from 'components/DisplayContainer'
import { HeaderBar } from 'components/HeaderBar'

import { CallbackPage } from 'pages/Callback'

export const SignupRouter = (): JSX.Element => {
  return (
    <Switch>
      <Route path={['/callback', '/form']}>
        <DisplayContainer>
          <HeaderBar showLogo={false} />
          <CallbackPage />
        </DisplayContainer>
      </Route>

      <Route path="/:campaignId">
        <CampaignRouter />
      </Route>
    </Switch>
  )
}
