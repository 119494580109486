import { ReactNode } from 'react'
import { IconType } from 'react-icons/lib'
import { HStack, Icon } from '@chakra-ui/react'

type HelpInfoEntryProps = {
  icon: IconType
  children: ReactNode
  isFlipIcon?: boolean
  align?: 'flex-start' | 'center'
}

const HelpInfoEntry = ({
  icon,
  children,
  isFlipIcon = false,
  align = 'center',
}: HelpInfoEntryProps) => {
  return (
    <HStack align={align} spacing="10px" color="neutral.800">
      <Icon
        as={icon}
        boxSize="20px"
        color="neutral.700"
        transform={isFlipIcon ? 'scaleX(-1)' : undefined}
      />
      {children}
    </HStack>
  )
}

export default HelpInfoEntry
